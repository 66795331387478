import { all } from "redux-saga/effects"
import analytics from "./analytics"
import balances from "./balances"
import competitions from "./competitions"
import dashboard from "./dashboard"
import orders from "./orders"
import user from "./user"
import backtests from "./backtesting"
import bots from "./bots"
import overview from "./overview"
import betExplorer from "./betexplorer"
import gameCenter from "./gamecenter"
import users from "./users"
import everymatrix from "./everymatrix"
import runningball from "./runningball"
import broker from "./broker"
import model from "./model"

function* rootSaga() {
  yield all([
    user(),
    orders(),
    competitions(),
    dashboard(),
    analytics(),
    balances(),
    backtests(),
    bots(),
    overview(),
    betExplorer(),
    gameCenter(),
    users(),
    everymatrix(),
    runningball(),
    broker(),
    model(),
  ])
}

export default rootSaga
