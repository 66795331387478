import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  Type,
  signInSucceeded,
  fetchMyself as fetchMyself_1,
  signInFailed,
  fetchMyselfSucceeded,
  fetchMyselfFailed,
  signOutFailed,
} from "../actions/user"
import { get, postRaw } from "../lib/fetch"

function* watchOAuthSucceeded() {
  return yield takeLatest(Type.OAUTH_SUCCEEDED, signIn)
}

function* watchFetchMyself() {
  return yield takeLatest(Type.FETCH_MYSELF, fetchMyself)
}

function* watchSignOut() {
  return yield takeLatest(Type.SIGN_OUT, signOut)
}

function* signIn({ res }) {
  try {
    // Response sets the session cookie
    yield call(postRaw, "/api/sso/sign_in", res.credential)
    yield put(signInSucceeded())
    yield put(fetchMyself_1())
  } catch (e) {
    // Ensure error object is serializable
    const serializableError =
      e && typeof e === "object"
        ? {
            message: e.message || "Unknown error",
            ...JSON.parse(JSON.stringify(e)),
          }
        : { message: String(e) }
    yield put(signInFailed(serializableError))
  }
}

function* fetchMyself() {
  try {
    const myself = yield call(get, "/api/user/myself")
    // Ensure we only pass serializable data to the reducer
    const serializableMyself = JSON.parse(JSON.stringify(myself))
    yield put(fetchMyselfSucceeded(serializableMyself))
  } catch (e) {
    // Ensure error object is serializable
    const serializableError =
      e && typeof e === "object"
        ? {
            message: e.message || "Unknown error",
            ...JSON.parse(JSON.stringify(e)),
          }
        : { message: String(e) }
    yield put(fetchMyselfFailed(serializableError))
  }
}

function* signOut() {
  try {
    yield call(postRaw, "/api/sso/sign_out")
    window.location.reload() // Clear the state
  } catch (e) {
    // Ensure error object is serializable
    const serializableError =
      e && typeof e === "object"
        ? {
            message: e.message || "Unknown error",
            ...JSON.parse(JSON.stringify(e)),
          }
        : { message: String(e) }
    yield put(signOutFailed(serializableError))
  }
}

export default function* userSaga() {
  yield all([watchOAuthSucceeded(), watchFetchMyself(), watchSignOut()])
}
