import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchBookieShortcutsSucceeded,
  fetchBookieShortcutsFailed,
  addBookieShortcutSucceeded,
  addBookieShortcutFailed,
  deleteBookieShortcutSucceeded,
  deleteBookieShortcutFailed,
} from "../actions/everymatrix"
import { get, httpDelete, post } from "../lib/fetch"

function* watchFetchBookieShortcuts() {
  return yield takeLatest(Type.FETCH_BOOKIE_SHORTCUTS, fetchBookieShortcuts)
}

function* fetchBookieShortcuts() {
  try {
    const bookieShortcuts = yield call(get, `/api/trading/everymatrix/bookie-shortcuts`)
    yield put(fetchBookieShortcutsSucceeded(bookieShortcuts))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchBookieShortcutsFailed(e))
  }
}

// ---

function* watchAddBookieShortcut() {
  return yield takeLatest(Type.ADD_BOOKIE_SHORTCUT, addBookieShortcut)
}

function* addBookieShortcut(action) {
  const { bookieId, shortcut } = action
  try {
    yield call(post, `/api/trading/everymatrix/bookie-shortcuts`, {
      bookieId,
      shortcut,
    })
    yield put(addBookieShortcutSucceeded(bookieId, shortcut))
  } catch (e) {
    console.log("Error", e)
    yield put(addBookieShortcutFailed(bookieId, shortcut, e))
  }
}

// ---

function* watchDeleteBookieShortcut() {
  return yield takeLatest(Type.DELETE_BOOKIE_SHORTCUT, deleteBookieShortcut)
}

function* deleteBookieShortcut(action) {
  const { bookieId } = action
  try {
    yield call(httpDelete, `/api/trading/everymatrix/bookie-shortcuts/${bookieId}`)
    yield put(deleteBookieShortcutSucceeded(bookieId))
  } catch (e) {
    console.log("Error", e)
    yield put(deleteBookieShortcutFailed(bookieId, e))
  }
}

// ---

export default function* betExplorerSaga() {
  yield all([watchFetchBookieShortcuts(), watchAddBookieShortcut(), watchDeleteBookieShortcut()])
}
