import { all, call, put, select, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchDashboardStatsSucceeded,
  fetchDashboardStatsFailed,
  fetchDashboardGraphSucceeded,
  fetchDashboardGraphFailed,
} from "../actions/dashboard"
import { post } from "../lib/fetch"
import { convertFilter } from "./ordersFilter"

function* watchFetchDashboardStats() {
  return yield takeLatest(Type.FETCH_DASHBOARD_STATS, fetchDashboardStats)
}

function* fetchDashboardStats(action) {
  try {
    let filter = yield select((state) => convertFilter(state.orders.filterFields))
    let includeBetslips = yield select((state) => state.orders.includeBetslips)

    filter = {
      ...filter,
      ...action.fixedFilter,
    }

    let page = yield call(post, `/api/trading/orders/analytics?includeBetslips=${includeBetslips}`, filter)
    const sharpeRatios = yield call(post, `/api/trading/orders/sharpe-ratio`, filter)

    page = {
      ...page,
      ...sharpeRatios,
    }

    yield put(fetchDashboardStatsSucceeded(page))
  } catch (e) {
    yield put(fetchDashboardStatsFailed(e))
  }
}

// ---

function* watchFetchDashboardGraph() {
  return yield takeLatest(Type.FETCH_DASHBOARD_GRAPH, fetchDashboardGraph)
}

function* fetchDashboardGraph(action) {
  try {
    let filter = yield select((state) => convertFilter(state.orders.filterFields))
    let includeBetslips = yield select((state) => state.orders.includeBetslips)
    let byTime = yield select((state) => state.dashboard.byTime)

    if (action.useBacktestingFilter) {
      filter = yield select((state) => convertFilter(state.backtesting.filterFields))
    }

    filter = {
      ...filter,
      ...action.fixedFilter,
    }

    let page
    if (byTime) {
      page = yield call(
        post,
        `/api/trading/orders/analytics?groupBy=fixtureStartTimestamp(auto)&includeBetslips=${includeBetslips}`,
        filter,
      )
    } else {
      page = yield call(post, `/api/trading/orders/analytics?groupBy=orderId()`, filter)
    }

    yield put(fetchDashboardGraphSucceeded(page))
  } catch (e) {
    yield put(fetchDashboardGraphFailed(e))
  }
}

export default function* dashboardSaga() {
  yield all([watchFetchDashboardStats(), watchFetchDashboardGraph()])
}
