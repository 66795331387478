export const Type = {
  FETCH_ORDERS: "backtesting/FETCH_ORDERS",
  FETCH_ORDERS_SUCCEEDED: "backtesting/FETCH_ORDERS_SUCCEEDED",
  FETCH_ORDERS_FAILED: "backtesting/FETCH_ORDERS_FAILED",
  TABLE_CHANGED: "backtesting/TABLE_CHANGED",
  FILTER_FIELDS_CHANGED: "backtesting/FILTER_FIELDS_CHANGED",
  ORDER_OPENED: "backtesting/ORDER_OPENED",
  ORDER_CLOSED: "backtesting/ORDER_CLOSED",
  FETCH_ORDER_PRICE_GRAPH: "backtesting/FETCH_ORDER_PRICE_GRAPH",
  FETCH_ORDER_PRICE_GRAPH_SUCCEEDED: "backtesting/FETCH_ORDER_PRICE_GRAPH_SUCCEEDED",
  FETCH_ORDER_PRICE_GRAPH_FAILED: "backtesting/FETCH_ORDER_PRICE_GRAPH_FAILED",
  FETCH_MODEL_PRICE_GRAPH: "backtesting/FETCH_MODEL_PRICE_GRAPH",
  FETCH_MODEL_PRICE_GRAPH_SUCCEEDED: "backtesting/FETCH_MODEL_PRICE_GRAPH_SUCCEEDED",
  FETCH_MODEL_PRICE_GRAPH_FAILED: "backtesting/FETCH_MODEL_PRICE_GRAPH_FAILED",
  FETCH_FIXTURE_GRAPH: "backtesting/FETCH_FIXTURE_GRAPH",
  FETCH_FIXTURE_GRAPH_SUCCEEDED: "backtesting/FETCH_FIXTURE_GRAPH_SUCCEEDED",
  FETCH_FIXTURE_GRAPH_FAILED: "backtesting/FETCH_FIXTURE_GRAPH_FAILED",
  FETCH_BACKTESTS: "backtesting/FETCH_BACKTESTS",
  FETCH_BACKTESTS_SUCCEEDED: "backtesting/FETCH_BACKTESTS_SUCCEEDED",
  FETCH_BACKTESTS_FAILED: "backtesting/FETCH_ORDERS_FAILED",
  FETCH_BACKTEST_PROGRESS: "backtesting/FETCH_BACKTEST_PROGRESS",
  FETCH_BACKTEST_PROGRESS_SUCCEEDED: "backtesting/FETCH_BACKTEST_PROGRESS_SUCCEEDED",
  FETCH_BACKTEST_PROGRESS_FAILED: "backtesting/FETCH_BACKTEST_PROGRESS_FAILED",
  FETCH_DASHBOARD_STATS: "backtesting/FETCH_DASHBOARD_STATS",
  FETCH_DASHBOARD_STATS_SUCCEEDED: "backtesting/FETCH_DASHBOARD_STATS_SUCCEEDED",
  FETCH_DASHBOARD_STATS_FAILED: "backtesting/FETCH_DASHBOARD_STATS_FAILED",
  FETCH_DASHBOARD_GRAPH: "backtesting/FETCH_DASHBOARD_GRAPH",
  FETCH_DASHBOARD_GRAPH_SUCCEEDED: "backtesting/FETCH_DASHBOARD_GRAPH_SUCCEEDED",
  FETCH_DASHBOARD_GRAPH_FAILED: "backtesting/FETCH_DASHBOARD_GRAPH_FAILED",
  FETCH_ANALYTICS: "backtesting/FETCH_ANALYTICS",
  FETCH_ANALYTICS_SUCCEEDED: "backtesting/FETCH_ANALYTICS_SUCCEEDED",
  FETCH_ANALYTICS_FAILED: "backtesting/FETCH_ANALYTICS_FAILED",
  SET_GROUP_BY: "backtesting/SET_GROUP_BY",
  SHOW_BEST: "backtesting/SHOW_BEST",
  SET_FLAT_STAKING: "backtesting/SET_FLAT_STAKING",
  SET_COMMISSION: "backtesting/SET_COMMISSION",
  SET_ADDITIONAL_COLUMNS: "backtesting/SET_ADDITIONAL_COLUMNS",
  SET_CUSTOM_STAKING: "backtesting/SET_CUSTOM_STAKING",
  SET_CUSTOM_STAKING_ENABLED: "backtesting/SET_CUSTOM_STAKING_ENABLED",
  FETCH_BOT_MATCH: "backtesting/FETCH_BOT_MATCH",
  FETCH_BOT_MATCH_SUCCEEDED: "backtesting/FETCH_BOT_MATCH_SUCCEEDED",
  FETCH_BOT_MATCH_FAILED: "backtesting/FETCH_BOT_MATCH_FAILED",
  CLEAR_NODES: "backtesting/CLEAR_NODES",
  CHANGE_NOTE: "backtesting/CHANGE_NOTE",
  CHANGE_NOTE_SUCCEEDED: "backtesting/CHANGE_NOTE_SUCCEEDED",
  CHANGE_NOTE_FAILED: "backtesting/CHANGE_NOTE_FAILED",
  BROWSE_GAME_CENTER: "backtesting/BROWSE_GAME_CENTER",
  BROWSE_GAME_CENTER_SUCCEEDED: "backtesting/BROWSE_GAME_CENTER_SUCCEEDED",
  BROWSE_GAME_CENTER_FAILED: "backtesting/BROWSE_GAME_CENTER_FAILED",
  SET_BROWSE_GAME_CENTER_BOT_PRINCIPAL_ID: "backtesting/SET_BROWSE_GAME_CENTER_BOT_PRINCIPAL_ID",
  SET_BROWSE_GAME_CENTER_COMPARE_PRINCIPAL_ID: "backtesting/SET_BROWSE_GAME_CENTER_COMPARE_PRINCIPAL_ID",
  SET_ORDER_PRICE_GRAPH_EMA_PERIOD: "backtesting/SET_ORDER_PRICE_GRAPH_EMA_PERIOD",
  SET_GAME_CENTER_CUSTOM_FILTER: "backtesting/SET_GAME_CENTER_CUSTOM_FILTER",
}

export function fetchOrders(fixedFilter) {
  return { type: Type.FETCH_ORDERS, fixedFilter }
}

export function fetchOrdersSucceeded(page) {
  return { type: Type.FETCH_ORDERS_SUCCEEDED, page }
}

export function fetchOrdersFailed(error) {
  return { type: Type.FETCH_ORDERS_FAILED, error }
}

export function tableChanged(pagination, filter, sorter) {
  return { type: Type.TABLE_CHANGED, pagination, filter, sorter }
}

export function filterFieldsChanged(fields) {
  return { type: Type.FILTER_FIELDS_CHANGED, fields }
}

export function orderOpened(order) {
  return { type: Type.ORDER_OPENED, order }
}

export function orderClosed() {
  return { type: Type.ORDER_CLOSED }
}

export function fetchOrderPriceGraph(orderUuid, bookie) {
  return { type: Type.FETCH_ORDER_PRICE_GRAPH, orderUuid, bookie }
}

export function fetchOrderPriceGraphSucceeded(bookie, values) {
  return { type: Type.FETCH_ORDER_PRICE_GRAPH_SUCCEEDED, bookie, values }
}

export function fetchOrderPriceGraphFailed(bookie, error) {
  return { type: Type.FETCH_ORDER_PRICE_GRAPH_FAILED, bookie, error }
}

export function fetchModelPriceGraph(orderUuid, homeScoreRate, awayScoreRate, nu, zeroAdjustment) {
  return {
    type: Type.FETCH_MODEL_PRICE_GRAPH,
    orderUuid,
    homeScoreRate,
    awayScoreRate,
    nu,
    zeroAdjustment,
  }
}

export function fetchModelPriceGraphSucceeded(values) {
  return { type: Type.FETCH_MODEL_PRICE_GRAPH_SUCCEEDED, values }
}

export function fetchModelPriceGraphFailed(error) {
  return { type: Type.FETCH_MODEL_PRICE_GRAPH_FAILED, error }
}

export function fetchFixtureGraph(orderUuid) {
  return { type: Type.FETCH_FIXTURE_GRAPH, orderUuid }
}

export function fetchFixtureGraphSucceeded(values) {
  return { type: Type.FETCH_FIXTURE_GRAPH_SUCCEEDED, values }
}

export function fetchFixtureGraphFailed(error) {
  return { type: Type.FETCH_FIXTURE_GRAPH_FAILED, error }
}

export function fetchBacktests() {
  return { type: Type.FETCH_BACKTESTS }
}

export function fetchBacktestsSucceeded(backtests) {
  return { type: Type.FETCH_BACKTESTS_SUCCEEDED, backtests }
}

export function fetchBacktestsFailed(error) {
  return { type: Type.FETCH_BACKTESTS_FAILED, error }
}

export function fetchBacktestProgress(principalId) {
  return { type: Type.FETCH_BACKTEST_PROGRESS, principalId }
}

export function fetchBacktestProgressSucceeded(principalId, progress) {
  return {
    type: Type.FETCH_BACKTEST_PROGRESS_SUCCEEDED,
    principalId,
    progress,
  }
}

export function fetchBacktestProgressFailed(principalId, error) {
  return { type: Type.FETCH_BACKTEST_PROGRESS_FAILED, principalId, error }
}

export function fetchDashboardStats(fixedFilter) {
  return { type: Type.FETCH_DASHBOARD_STATS, fixedFilter }
}

export function fetchDashboardStatsSucceeded(page) {
  return { type: Type.FETCH_DASHBOARD_STATS_SUCCEEDED, page }
}

export function fetchDashboardStatsFailed(error) {
  return { type: Type.FETCH_DASHBOARD_STATS_FAILED, error }
}

export function fetchDashboardGraph(principalId) {
  return { type: Type.FETCH_DASHBOARD_GRAPH, principalId }
}

export function fetchDashboardGraphSucceeded(principalId, page) {
  return { type: Type.FETCH_DASHBOARD_GRAPH_SUCCEEDED, principalId, page }
}

export function fetchDashboardGraphFailed(principalId, error) {
  return { type: Type.FETCH_DASHBOARD_GRAPH_FAILED, principalId, error }
}

export function fetchAnalytics(fixedFilter) {
  return { type: Type.FETCH_ANALYTICS, fixedFilter }
}

export function fetchAnalyticsSucceeded(analytics) {
  return { type: Type.FETCH_ANALYTICS_SUCCEEDED, analytics }
}

export function fetchAnalyticsFailed(error) {
  return { type: Type.FETCH_ANALYTICS_FAILED, error }
}

export function setGroupBy(groupBy) {
  return { type: Type.SET_GROUP_BY, groupBy }
}

export function setShowBest(showBest) {
  return { type: Type.SHOW_BEST, showBest }
}

export function setFlatStaking(flatStaking) {
  return { type: Type.SET_FLAT_STAKING, flatStaking }
}

export function setCommission(commission) {
  return { type: Type.SET_COMMISSION, commission }
}

export function setAdditionalColumns(additionalColumns) {
  return { type: Type.SET_ADDITIONAL_COLUMNS, additionalColumns }
}

export function setCustomStakingEnabled(customStakingEnabled) {
  return { type: Type.SET_CUSTOM_STAKING_ENABLED, customStakingEnabled }
}

export function setCustomStaking(field, value) {
  return { type: Type.SET_CUSTOM_STAKING, field, value }
}

export function fetchBotMatch(fixtureId, botAccount, botPrincipalId, backtestPrincipalId) {
  return {
    type: Type.FETCH_BOT_MATCH,
    fixtureId,
    botAccount,
    botPrincipalId,
    backtestPrincipalId,
  }
}

export function fetchBotMatchSucceeded(matches) {
  return { type: Type.FETCH_BOT_MATCH_SUCCEEDED, matches }
}

export function fetchBotMatchFailed(error) {
  return { type: Type.FETCH_BOT_MATCH_FAILED, error }
}

export function clearNodes(principalId) {
  return { type: Type.CLEAR_NODES, principalId }
}

export function changeNote(principalId, note) {
  return { type: Type.CHANGE_NOTE, principalId, note }
}

export function changeNoteFailed(principalId, e) {
  return { type: Type.CHANGE_NOTE_FAILED, principalId, e }
}

export function changeNoteSucceeded(principalId) {
  return { type: Type.CHANGE_NOTE_SUCCEEDED, principalId }
}

export function browseGameCenter(principalId, from, to) {
  return { type: Type.BROWSE_GAME_CENTER, principalId, from, to }
}

export function browseGameCenterSucceeded(response) {
  return { type: Type.BROWSE_GAME_CENTER_SUCCEEDED, response }
}

export function browseGameCenterFailed(error) {
  return { type: Type.BROWSE_GAME_CENTER_FAILED, error }
}

export function setBrowseGameCenterBotPrincipalId(botAccount, botPrincipalId) {
  return {
    type: Type.SET_BROWSE_GAME_CENTER_BOT_PRINCIPAL_ID,
    botAccount,
    botPrincipalId,
  }
}

export function setBrowseGameCenterComparePrincipalId(comparePrincipalId) {
  return {
    type: Type.SET_BROWSE_GAME_CENTER_COMPARE_PRINCIPAL_ID,
    comparePrincipalId,
  }
}

export function setOrderPriceGraphEmaPeriod(emaPeriod) {
  return { type: Type.SET_ORDER_PRICE_GRAPH_EMA_PERIOD, emaPeriod }
}

export function setGameCenterCustomFilter(customFilter) {
  return { type: Type.SET_GAME_CENTER_CUSTOM_FILTER, customFilter }
}
