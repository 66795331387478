import { combineReducers } from "redux"
import { Type } from "../actions/competitions"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITIONS:
      return true
    case Type.FETCH_COMPETITIONS_FAILED:
    case Type.FETCH_COMPETITIONS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITIONS_FAILED:
      return action.error
    case Type.FETCH_COMPETITIONS:
      return null
    default:
      return state
  }
}

const defaultTable = {
  pagination: {
    pageSize: 20,
    showSizeChanger: false,
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}–${range[1]} of ${total} items`,
  },
}

function table(state = JSON.parse(JSON.stringify(defaultTable)), action) {
  switch (action.type) {
    case Type.TABLE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.pagination.current,
        },
      }
    case Type.FETCH_COMPETITIONS_SUCCEEDED: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.page.page,
          total: action.page.totalCount,
        },
      }
    }
    default:
      return state
  }
}

function page(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITIONS_SUCCEEDED:
      return action.page
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  table,
  page,
})

export default reducer
