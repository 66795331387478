import { combineReducers } from "redux"
import { Type } from "../actions/betexplorer"

function duplicateTeamMatchesLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_DUPLICATE_TEAM_MATCHES:
      return true
    case Type.FETCH_DUPLICATE_TEAM_MATCHES_SUCCEEDED:
    case Type.FETCH_DUPLICATE_TEAM_MATCHES_FAILED:
      return false
    default:
      return state
  }
}

function duplicateTeamMatchesError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_DUPLICATE_TEAM_MATCHES_FAILED:
      return action.error
    case Type.FETCH_DUPLICATE_TEAM_MATCHES:
      return null
    default:
      return state
  }
}

function duplicateTeamMatches(state = [], action) {
  switch (action.type) {
    case Type.FETCH_DUPLICATE_TEAM_MATCHES_SUCCEEDED:
      return action.duplicateTeamMatches
    case Type.FETCH_ORDERS_FAILED:
      return []
    default:
      return state
  }
}

function teamMatchLoading(state = false, action) {
  switch (action.type) {
    case Type.OPEN_TEAM_MATCH:
    case Type.FETCH_TEAM_MATCH:
      return true
    case Type.FETCH_TEAM_MATCH_SUCCEEDED:
    case Type.FETCH_TEAM_MATCH_FAILED:
      return false
    default:
      return state
  }
}

function teamMatchError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_TEAM_MATCH_FAILED:
      return action.error
    case Type.FETCH_TEAM_MATCH:
      return null
    default:
      return state
  }
}

function teamMatch(state = null, action) {
  switch (action.type) {
    case Type.FETCH_TEAM_MATCH_SUCCEEDED:
      return action.teamMatch
    default:
      return state
  }
}

function teamMatchOpen(state = false, action) {
  switch (action.type) {
    case Type.OPEN_TEAM_MATCH:
      return true
    case Type.CLOSE_TEAM_MATCH:
      return false
    default:
      return state
  }
}

function deleteTeamMatchLoading(state = false, action) {
  switch (action.type) {
    case Type.DELETE_TEAM_MATCH:
      return true
    case Type.DELETE_TEAM_MATCH_SUCCEEDED:
    case Type.DELETE_TEAM_MATCH_FAILED:
      return false
    default:
      return state
  }
}

function deleteTeamMatchError(state = null, action) {
  switch (action.type) {
    case Type.DELETE_TEAM_MATCH_FAILED:
      return action.error
    case Type.DELETE_TEAM_MATCH:
      return null
    default:
      return state
  }
}

const defaultTable = {
  pagination: {
    pageSize: 20,
    size: "small",
    showSizeChanger: false,
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}–${range[1]} of ${total} items`,
  },
}

function teamMatchesFilter(state = null, action) {
  switch (action.type) {
    case Type.TEAM_MATCHES_FILTER_CHANGED:
      return action.filter
    default:
      return state
  }
}

function teamMatchesTable(state = JSON.parse(JSON.stringify(defaultTable)), action) {
  switch (action.type) {
    case Type.TEAM_MATCHES_TABLE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.pagination.current,
        },
      }
    case Type.FETCH_TEAM_MATCHES_SUCCEEDED: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.page.page,
          total: action.page.totalCount,
        },
      }
    }
    case Type.TEAM_MATCHES_FILTER_APPLIED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: 1,
        },
      }
    default:
      return state
  }
}

function teamMatchesLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_TEAM_MATCHES:
      return true
    case Type.FETCH_TEAM_MATCHES_SUCCEEDED:
    case Type.FETCH_TEAM_MATCHES_FAILED:
      return false
    default:
      return state
  }
}

function teamMatchesError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_TEAM_MATCHES_FAILED:
      return action.error
    case Type.FETCH_TEAM_MATCHES:
      return null
    default:
      return state
  }
}

function teamMatchesPage(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_TEAM_MATCHES_SUCCEEDED:
      return action.page
    case Type.FETCH_TEAM_MATCHES_FAILED:
      return {}
    default:
      return state
  }
}

function competitionMatchesFilter(state = null, action) {
  switch (action.type) {
    case Type.COMPETITION_MATCHES_FILTER_CHANGED:
      return action.filter
    default:
      return state
  }
}

function competitionMatchesTable(state = JSON.parse(JSON.stringify(defaultTable)), action) {
  switch (action.type) {
    case Type.COMPETITION_MATCHES_TABLE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.pagination.current,
        },
      }
    case Type.FETCH_COMPETITION_MATCHES_SUCCEEDED: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.page.page,
          total: action.page.totalCount,
        },
      }
    }
    case Type.COMPETITION_MATCHES_FILTER_APPLIED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: 1,
        },
      }
    default:
      return state
  }
}

function competitionMatchesLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITION_MATCHES:
      return true
    case Type.FETCH_COMPETITION_MATCHES_SUCCEEDED:
    case Type.FETCH_COMPETITION_MATCHES_FAILED:
      return false
    default:
      return state
  }
}

function competitionMatchesError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITION_MATCHES_FAILED:
      return action.error
    case Type.FETCH_COMPETITION_MATCHES:
      return null
    default:
      return state
  }
}

function competitionMatchesPage(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITION_MATCHES_SUCCEEDED:
      return action.page
    case Type.FETCH_COMPETITION_MATCHES_FAILED:
      return {}
    default:
      return state
  }
}

function competitionMatchLoading(state = false, action) {
  switch (action.type) {
    case Type.OPEN_COMPETITION_MATCH:
    case Type.FETCH_COMPETITION_MATCH:
      return true
    case Type.FETCH_COMPETITION_MATCH_SUCCEEDED:
    case Type.FETCH_COMPETITION_MATCH_FAILED:
      return false
    default:
      return state
  }
}

function competitionMatchError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITION_MATCH_FAILED:
      return action.error
    case Type.FETCH_COMPETITION_MATCH:
      return null
    default:
      return state
  }
}

function competitionMatch(state = null, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITION_MATCH_SUCCEEDED:
      return action.competitionMatch
    default:
      return state
  }
}

function competitionMatchOpen(state = false, action) {
  switch (action.type) {
    case Type.OPEN_COMPETITION_MATCH:
      return true
    case Type.CLOSE_COMPETITION_MATCH:
      return false
    default:
      return state
  }
}

const reducer = combineReducers({
  duplicateTeamMatchesLoading,
  duplicateTeamMatchesError,
  duplicateTeamMatches,
  teamMatchLoading,
  teamMatchError,
  teamMatch,
  teamMatchOpen,
  deleteTeamMatchLoading,
  deleteTeamMatchError,
  teamMatchesTable,
  teamMatchesLoading,
  teamMatchesError,
  teamMatchesPage,
  teamMatchesFilter,
  competitionMatchesTable,
  competitionMatchesLoading,
  competitionMatchesError,
  competitionMatchesPage,
  competitionMatchesFilter,
  competitionMatchLoading,
  competitionMatchError,
  competitionMatch,
  competitionMatchOpen,
})

export default reducer
