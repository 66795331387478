import { combineReducers } from "redux"
import { Type } from "../actions/everymatrix"

function bookieShortcutsLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_BOOKIE_SHORTCUTS:
      return true
    case Type.FETCH_BOOKIE_SHORTCUTS_SUCCEEDED:
    case Type.FETCH_BOOKIE_SHORTCUTS_FAILED:
      return false
    default:
      return state
  }
}

function addBookieShortcutLoading(state = false, action) {
  switch (action.type) {
    case Type.ADD_BOOKIE_SHORTCUT:
      return true
    case Type.ADD_BOOKIE_SHORTCUT_FAILED:
    case Type.ADD_BOOKIE_SHORTCUT_SUCCEEDED:
      return false
    default:
      return state
  }
}

function deleteBookieShortcutLoading(state = false, action) {
  switch (action.type) {
    case Type.DELETE_BOOKIE_SHORTCUT:
      return true
    case Type.DELETE_BOOKIE_SHORTCUT_FAILED:
    case Type.DELETE_BOOKIE_SHORTCUT_SUCCEEDED:
      return false
    default:
      return state
  }
}

function bookieShortcutsError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_BOOKIE_SHORTCUTS_FAILED:
    case Type.ADD_BOOKIE_SHORTCUT_FAILED:
    case Type.DELETE_BOOKIE_SHORTCUT_FAILED:
      return action.error
    case Type.FETCH_BOOKIE_SHORTCUTS:
    case Type.ADD_BOOKIE_SHORTCUT:
    case Type.DELETE_BOOKIE_SHORTCUT:
      return null
    default:
      return state
  }
}

function bookieShortcuts(state = [], action) {
  switch (action.type) {
    case Type.FETCH_BOOKIE_SHORTCUTS_SUCCEEDED:
      return action.bookieShortcuts
    case Type.ADD_BOOKIE_SHORTCUT_SUCCEEDED: {
      const index = state.findIndex((r) => r.bookieId === action.bookieId)
      const newShortcut = {
        bookieId: action.bookieId,
        shortcut: action.shortcut,
      }
      if (index === -1) {
        return [...state, newShortcut]
      } else {
        return state.map((item, i) => (i === index ? newShortcut : item))
      }
    }
    case Type.DELETE_BOOKIE_SHORTCUT_SUCCEEDED: {
      const index = state.findIndex((r) => r.bookieId === action.bookieId)
      if (index !== -1) {
        return state.filter((_, i) => i !== index)
      } else return state
    }
    default:
      return state
  }
}

const reducer = combineReducers({
  bookieShortcutsLoading,
  addBookieShortcutLoading,
  deleteBookieShortcutLoading,
  bookieShortcutsError,
  bookieShortcuts,
})

export default reducer
