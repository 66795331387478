import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { Type, fetchCompetitionsSucceeded, fetchCompetitionsFailed } from "../actions/competitions"
import { get } from "../lib/fetch"

function* watchFetchCompetitions() {
  return yield takeLatest(Type.FETCH_COMPETITIONS, fetchCompetitions)
}

function* fetchCompetitions() {
  try {
    const table = yield select((state) => state.competitions.table)

    const current = table?.pagination?.current || 1
    const pageSize = table?.pagination?.pageSize || 20

    const page = yield call(get, `/api/trading/competitions?page=${current}&pageSize=${pageSize}`)
    yield put(fetchCompetitionsSucceeded(page))
  } catch (e) {
    yield put(fetchCompetitionsFailed(e))
  }
}

export default function* competitionsSaga() {
  yield all([watchFetchCompetitions()])
}
