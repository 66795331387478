import React, { useState, createContext, useContext, useCallback } from "react"
import { ConfigProvider } from "antd"
import themeConfig from "./themeConfig"
import { getLightTheme, getDarkTheme } from "./themeUtils"

// Create a context for theme management
export const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
})

// Hook to use the theme context
export const useTheme = () => useContext(ThemeContext)

/**
 * AppThemeProvider applies the Ant Design theme configuration to the application
 * It wraps the entire app to provide consistent theming and supports theme switching
 */
const AppThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [currentTheme, setCurrentTheme] = useState(themeConfig)

  // Toggle between light and dark themes
  const toggleTheme = useCallback(() => {
    const newIsDarkMode = !isDarkMode
    setIsDarkMode(newIsDarkMode)
    setCurrentTheme(newIsDarkMode ? getDarkTheme() : getLightTheme())
  }, [isDarkMode])

  // Context value
  const themeContextValue = {
    isDarkMode,
    toggleTheme,
  }

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <ConfigProvider theme={currentTheme}>{children}</ConfigProvider>
    </ThemeContext.Provider>
  )
}

export default AppThemeProvider
