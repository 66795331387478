import { theme } from "antd"
import themeConfig from "./themeConfig"

/**
 * Get the current theme configuration
 * @returns {Object} The current theme configuration
 */
export const getThemeConfig = () => {
  return themeConfig
}

/**
 * Create a modified theme configuration
 * @param {Object} overrides - Token overrides to apply to the base theme
 * @returns {Object} A new theme configuration with the overrides applied
 */
export const createTheme = (overrides = {}) => {
  const { token: baseToken = {}, components: baseComponents = {} } = themeConfig
  const { token: overrideToken = {}, components: overrideComponents = {} } = overrides

  return {
    token: {
      ...baseToken,
      ...overrideToken,
    },
    components: {
      ...baseComponents,
      ...overrideComponents,
    },
  }
}

/**
 * Get the algorithm for theme generation
 * @param {boolean} isDark - Whether to use dark theme
 * @returns {Function[]} The algorithm functions to use
 */
export const getAlgorithm = (isDark = false) => {
  return isDark ? [theme.darkAlgorithm, theme.compactAlgorithm] : [theme.defaultAlgorithm]
}

/**
 * Apply a dark theme
 * @returns {Object} Dark theme configuration
 */
export const getDarkTheme = () => {
  return {
    ...themeConfig,
    algorithm: getAlgorithm(true),
  }
}

/**
 * Apply a light theme
 * @returns {Object} Light theme configuration
 */
export const getLightTheme = () => {
  return {
    ...themeConfig,
    algorithm: getAlgorithm(false),
  }
}
