import { combineReducers } from "redux"
import { Type } from "../actions/orders"
import { notification } from "antd"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_ORDERS:
      return true
    case Type.FETCH_ORDERS_FAILED:
    case Type.FETCH_ORDERS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_ORDERS_FAILED:
      return action.error
    case Type.FETCH_ORDERS:
      return null
    default:
      return state
  }
}

const defaultTable = {
  pagination: {
    pageSize: 20,
    size: "small",
    showSizeChanger: false,
    showQuickJumper: true,
    // showTotal: (total, range) => `${range[0]}–${range[1]} of ${total} items`,
  },
}

const defaultOrderPriceGraph = {
  fromTimestamp: null,
  toTimestamp: null,
  orderId: null,
  values: {},
  fixture: [],
  runningball: {
    events: [],
    interruptedIntervals: [],
  },
  error: null,
}

const defaultOrderChildren = {
  orderId: null,
  orders: [],
  error: null,
}

function table(state = defaultTable, action) {
  switch (action.type) {
    case Type.TABLE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.pagination.current,
        },
      }
    case Type.FETCH_ORDERS_SUCCEEDED: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.page.page,
          total: action.page.totalCount,
        },
      }
    }
    case Type.FILTER_FIELDS_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: 1,
        },
      }

    default:
      return state
  }
}

function filterFields(state = {}, action) {
  switch (action.type) {
    case Type.FILTER_FIELDS_CHANGED: {
      return { ...action.fields }
    }
    default:
      return state
  }
}

function page(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_ORDERS_SUCCEEDED:
      return JSON.parse(JSON.stringify(action.page))
    case Type.FETCH_ORDERS_FAILED:
      return {}
    case Type.SET_ORDER_VALID:
      if (state.list) {
        const index = state.list.findIndex((o) => o.betslipUuid === action.betslipUuid)
        if (index !== -1) {
          const newState = JSON.parse(JSON.stringify(state))
          newState.list[index].valid = action.valid
          return newState
        }
      }
      return state
    default:
      return state
  }
}

function selectedOrder(state = null, action) {
  switch (action.type) {
    case Type.ORDER_OPENED:
      return action.order
    case Type.SET_ORDER_VALID:
      if (state && state.betslipUuid === action.betslipUuid) {
        return { ...state, valid: action.valid }
      } else {
        return state
      }
    default:
      return state
  }
}

function selectedOrderOpen(state = false, action) {
  switch (action.type) {
    case Type.ORDER_OPENED:
      return true
    case Type.ORDER_CLOSED:
      return false
    default:
      return state
  }
}

function orderPriceGraph(state = defaultOrderPriceGraph, action) {
  switch (action.type) {
    case Type.ORDER_OPENED:
      return {
        ...defaultOrderPriceGraph,
        fromTimestamp: action.fromTimestamp,
        toTimestamp: action.toTimestamp,
        orderId: action.orderId,
        values: {},
        fixture: [],
        initialLoad: true,
        modelLoading: false,
        loadCounter: 0,
      }
    case Type.FETCH_ORDER_PRICE_GRAPH:
      return {
        ...state,
        loadCounter: state.loadCounter + 1,
      }
    case Type.FETCH_ORDER_PRICE_GRAPH_SUCCEEDED:
      if (Object.keys(action.values).length === 0) {
        notification.error({
          message: "No Graph Data",
          description: `Query for bookie ${action.bookie} yielded no results.`,
          duration: 5,
        })
      }
      return {
        ...state,
        values: {
          ...state.values,
          [action.bookie]: action.values,
        },
        loadCounter: state.loadCounter - 1,
        initialLoad: false,
      }
    case Type.FETCH_RUNNINGBALL_GRAPH:
    case Type.FETCH_FIXTURE_GRAPH:
      return {
        ...state,
        loadCounter: state.loadCounter + 1,
      }
    case Type.FETCH_MODEL_PRICE_GRAPH:
      const newValues = { ...state.values }
      delete newValues.model
      return {
        ...state,
        values: newValues,
        modelLoading: true,
        loadCounter: state.loadCounter + 1,
      }
    case Type.FETCH_MODEL_PRICE_GRAPH_SUCCEEDED:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.values,
        },
        loadCounter: state.loadCounter - 1,
        initialLoad: false,
        modelLoading: false,
      }
    case Type.FETCH_MODEL_PRICE_GRAPH_FAILED:
      const failedValues = { ...state.values }
      delete failedValues.model
      delete failedValues.pin88_true
      return {
        ...state,
        values: failedValues,
        loadCounter: state.loadCounter - 1,
        initialLoad: false,
        modelLoading: false,
      }
    case Type.FETCH_ORDER_PRICE_GRAPH_FAILED:
    case Type.FETCH_FIXTURE_GRAPH_FAILED:
      return {
        ...state,
        error: action.error,
        loadCounter: state.loadCounter - 1,
        initialLoad: false,
      }
    case Type.FETCH_FIXTURE_GRAPH_SUCCEEDED:
      return {
        ...state,
        fixture: action.values,
        loadCounter: state.loadCounter - 1,
        initialLoad: false,
      }
    case Type.FETCH_RUNNINGBALL_GRAPH_FAILED:
      return {
        ...state,
        error: action.error,
        loadCounter: state.loadCounter - 1,
        initialLoad: false,
      }
    case Type.FETCH_RUNNINGBALL_GRAPH_SUCCEEDED:
      return {
        ...state,
        runningball: JSON.parse(JSON.stringify(action.values)),
        loadCounter: state.loadCounter - 1,
        initialLoad: false,
      }
    default:
      return state
  }
}

function backgroundRefresh(state = false, action) {
  switch (action.type) {
    case Type.START_BACKGROUND_REFRESH:
      return true
    case Type.STOP_BACKGROUND_REFRESH:
    case Type.TABLE_CHANGED:
    case Type.FILTER_FIELDS_CHANGED:
    case Type.ORDER_OPENED:
      return false
    default:
      return state
  }
}

function values(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_VALUES:
      return {
        ...state,
        [action.field]: {
          loading: true,
          _filter: JSON.stringify(action.fixedFilter),
        },
      }
    case Type.FETCH_VALUES_SUCCEEDED:
      return {
        ...state,
        [action.field]: {
          loading: false,
          values: JSON.parse(JSON.stringify(action.values)),
          _filter: JSON.stringify(action.fixedFilter),
        },
      }
    case Type.FETCH_VALUES_FAILED:
      return {
        ...state,
        [action.field]: {
          loading: false,
          error: action.error,
          _filter: JSON.stringify(action.fixedFilter),
        },
      }
    default:
      return state
  }
}

function orderChildren(state = JSON.parse(JSON.stringify(defaultOrderChildren)), action) {
  switch (action.type) {
    case Type.FETCH_ORDER_CHILDREN:
      return {
        orderId: action.orderId,
        orders: [],
        loading: true,
      }
    case Type.FETCH_ORDER_CHILDREN_SUCCEEDED:
      return {
        ...state,
        orders: JSON.parse(JSON.stringify(action.orders)),
        loading: false,
      }
    case Type.FETCH_ORDER_CHILDREN_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

function includeBetslips(state = false, action) {
  switch (action.type) {
    case Type.TOGGLE_INCLUDE_BETSLIPS:
      return !state
    default:
      return state
  }
}

function flagsLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_FLAGS:
      return true
    case Type.FETCH_FLAGS_SUCCEEDED:
    case Type.FETCH_FLAGS_FAILED:
      return false
    default:
      return state
  }
}

function flags(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_FLAGS_SUCCEEDED:
      return action.flags
    case Type.FETCH_FLAGS_FAILED:
      return []
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  table,
  filterFields,
  page,
  selectedOrder,
  selectedOrderOpen,
  orderPriceGraph,
  orderChildren,
  backgroundRefresh,
  values,
  includeBetslips,
  flagsLoading,
  flags,
})

export default reducer
