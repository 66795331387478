export const Type = {
  FETCH_DUPLICATE_TEAM_MATCHES: "betexplorer/FETCH_DUPLICATE_TEAM_MATCHES",
  FETCH_DUPLICATE_TEAM_MATCHES_SUCCEEDED: "betexplorer/FETCH_DUPLICATE_TEAM_MATCHES_SUCCEEDED",
  FETCH_DUPLICATE_TEAM_MATCHES_FAILED: "betexplorer/FETCH_DUPLICATE_TEAM_MATCHES_FAILED",
  FETCH_TEAM_MATCH: "betexplorer/FETCH_TEAM_MATCH",
  FETCH_TEAM_MATCH_SUCCEEDED: "betexplorer/FETCH_TEAM_MATCH_SUCCEEDED",
  FETCH_TEAM_MATCH_FAILED: "betexplorer/FETCH_TEAM_MATCH_FAILED",
  OPEN_TEAM_MATCH: "betexplorer/OPEN_TEAM_MATCH",
  CLOSE_TEAM_MATCH: "betexplorer/CLOSE_TEAM_MATCH",
  DELETE_TEAM_MATCH: "betexplorer/DELETE_TEAM_MATCH",
  DELETE_TEAM_MATCH_SUCCEEDED: "betexplorer/DELETE_TEAM_MATCH_SUCCEEDED",
  DELETE_TEAM_MATCH_FAILED: "betexplorer/DELETE_TEAM_MATCH_FAILED",
  FETCH_TEAM_MATCHES: "betexplorer/FETCH_TEAM_MATCHES",
  FETCH_TEAM_MATCHES_SUCCEEDED: "betexplorer/FETCH_TEAM_MATCHES_SUCCEEDED",
  FETCH_TEAM_MATCHES_FAILED: "betexplorer/FETCH_TEAM_MATCHES_FAILED",
  TEAM_MATCHES_TABLE_CHANGED: "betexplorer/TEAM_MATCHES_TABLE_CHANGED",
  TEAM_MATCHES_FILTER_CHANGED: "betexplorer/TEAM_MATCHES_FILTER_CHANGED",
  TEAM_MATCHES_FILTER_APPLIED: "betexplorer/TEAM_MATCHES_FILTER_APPLIED",
  FETCH_COMPETITION_MATCHES: "betexplorer/FETCH_COMPETITION_MATCHES",
  FETCH_COMPETITION_MATCHES_SUCCEEDED: "betexplorer/FETCH_COMPETITION_MATCHES_SUCCEEDED",
  FETCH_COMPETITION_MATCHES_FAILED: "betexplorer/FETCH_COMPETITION_MATCHES_FAILED",
  COMPETITION_MATCHES_TABLE_CHANGED: "betexplorer/COMPETITION_MATCHES_TABLE_CHANGED",
  COMPETITION_MATCHES_FILTER_CHANGED: "betexplorer/COMPETITION_MATCHES_FILTER_CHANGED",
  COMPETITION_MATCHES_FILTER_APPLIED: "betexplorer/COMPETITION_MATCHES_FILTER_APPLIED",
  FETCH_COMPETITION_MATCH: "betexplorer/FETCH_COMPETITION_MATCH",
  FETCH_COMPETITION_MATCH_SUCCEEDED: "betexplorer/FETCH_COMPETITION_MATCH_SUCCEEDED",
  FETCH_COMPETITION_MATCH_FAILED: "betexplorer/FETCH_COMPETITION_MATCH_FAILED",
  OPEN_COMPETITION_MATCH: "betexplorer/OPEN_COMPETITION_MATCH",
  CLOSE_COMPETITION_MATCH: "betexplorer/CLOSE_TEAM_MATCH",
}

export function fetchDuplicateTeamMatches() {
  return { type: Type.FETCH_DUPLICATE_TEAM_MATCHES }
}

export function fetchDuplicateTeamMatchesSucceeded(duplicateTeamMatches) {
  return {
    type: Type.FETCH_DUPLICATE_TEAM_MATCHES_SUCCEEDED,
    duplicateTeamMatches,
  }
}

export function fetchDuplicateTeamMatchesFailed(error) {
  return { type: Type.FETCH_DUPLICATE_TEAM_MATCHES_FAILED, error }
}

export function fetchTeamMatch(teamId) {
  return { type: Type.FETCH_TEAM_MATCH, teamId }
}

export function fetchTeamMatchSucceeded(teamMatch) {
  return { type: Type.FETCH_TEAM_MATCH_SUCCEEDED, teamMatch }
}

export function fetchTeamMatchFailed(error) {
  return { type: Type.FETCH_TEAM_MATCH_FAILED, error }
}

export function openTeamMatch() {
  return { type: Type.OPEN_TEAM_MATCH }
}

export function closeTeamMatch() {
  return { type: Type.CLOSE_TEAM_MATCH }
}

export function deleteTeamMatch(teamId, betExplorerId) {
  return { type: Type.DELETE_TEAM_MATCH, teamId, betExplorerId }
}

export function deleteTeamMatchSucceeded(teamId) {
  return { type: Type.DELETE_TEAM_MATCH_SUCCEEDED, teamId }
}

export function deleteTeamMatchFailed(error) {
  return { type: Type.DELETE_TEAM_MATCH_FAILED, error }
}

export function fetchTeamMatches() {
  return { type: Type.FETCH_TEAM_MATCHES }
}

export function fetchTeamMatchesSucceeded(page) {
  return { type: Type.FETCH_TEAM_MATCHES_SUCCEEDED, page }
}

export function fetchTeamMatchesFailed(error) {
  return { type: Type.FETCH_TEAM_MATCHES_FAILED, error }
}

export function teamMatchesTableChanged(pagination, filter, sorter) {
  return { type: Type.TEAM_MATCHES_TABLE_CHANGED, pagination, filter, sorter }
}

export function teamMatchesFilterChanged(filter) {
  return { type: Type.TEAM_MATCHES_FILTER_CHANGED, filter }
}

export function teamMatchesFilterApplied() {
  return { type: Type.TEAM_MATCHES_FILTER_APPLIED }
}

export function fetchCompetitionMatches() {
  return { type: Type.FETCH_COMPETITION_MATCHES }
}

export function fetchCompetitionMatchesSucceeded(page) {
  return { type: Type.FETCH_COMPETITION_MATCHES_SUCCEEDED, page }
}

export function fetchCompetitionMatchesFailed(error) {
  return { type: Type.FETCH_COMPETITION_MATCHES_FAILED, error }
}

export function competitionMatchesTableChanged(pagination, filter, sorter) {
  return {
    type: Type.COMPETITION_MATCHES_TABLE_CHANGED,
    pagination,
    filter,
    sorter,
  }
}

export function competitionMatchesFilterChanged(filter) {
  return { type: Type.COMPETITION_MATCHES_FILTER_CHANGED, filter }
}

export function competitionMatchesFilterApplied() {
  return { type: Type.COMPETITION_MATCHES_FILTER_APPLIED }
}

export function fetchCompetitionMatch(competitionId) {
  return { type: Type.FETCH_COMPETITION_MATCH, competitionId }
}

export function fetchCompetitionMatchSucceeded(competitionMatch) {
  return { type: Type.FETCH_COMPETITION_MATCH_SUCCEEDED, competitionMatch }
}

export function fetchCompetitionMatchFailed(error) {
  return { type: Type.FETCH_COMPETITION_MATCH_FAILED, error }
}

export function openCompetitionMatch() {
  return { type: Type.OPEN_COMPETITION_MATCH }
}

export function closeCompetitionMatch() {
  return { type: Type.CLOSE_COMPETITION_MATCH }
}
