import { combineReducers } from "redux"
import { Type } from "../actions/users"

function usersLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_USERS:
      return true
    case Type.FETCH_USERS_FAILED:
    case Type.FETCH_USERS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function users(state = [], action) {
  switch (action.type) {
    case Type.FETCH_USERS_SUCCEEDED:
      return action.users
    case Type.FETCH_USERS_FAILED:
      return []
    case Type.DELETE_USER_SUCCEEDED: {
      const index = state.findIndex((user) => user.userId === action.userId)
      if (index !== -1) {
        return state.filter((_, i) => i !== index)
      }
      return state
    }
    case Type.CREATE_USER_SUCCEEDED: {
      return [...state, action.user]
    }
    case Type.ASSIGN_USER_AUTHORITIES_SUCCEEDED: {
      const index = state.findIndex((user) => user.userId === action.userId)
      if (index !== -1) {
        return state.map((user, i) => {
          if (i === index) {
            return {
              ...user,
              authorities: action.authorities,
            }
          }
          return user
        })
      }
      return state
    }
    case Type.ASSIGN_USER_ACCOUNTS_SUCCEEDED: {
      const index = state.findIndex((user) => user.userId === action.userId)
      if (index !== -1) {
        return state.map((user, i) => {
          if (i === index) {
            return {
              ...user,
              accountNames: action.accountNames,
            }
          }
          return user
        })
      }
      return state
    }
    default:
      return state
  }
}

const reducer = combineReducers({
  usersLoading,
  users,
})

export default reducer
