import { combineReducers } from "redux"
import { Type } from "../actions/balances"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_BALANCES:
      return true
    case Type.FETCH_BALANCES_FAILED:
    case Type.FETCH_BALANCES_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_BALANCES_FAILED:
      return action.error
    case Type.FETCH_BALANCES:
      return null
    default:
      return state
  }
}

function balances(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_BALANCES_SUCCEEDED:
      const result = { ...state }

      // Process each balance in the action
      Object.entries(action.balances).forEach(([key, newBalance]) => {
        const oldBalance = state[key] || {}

        // Calculate trends
        const balanceTrend =
          newBalance.balance === oldBalance.balance ? null : newBalance.balance > oldBalance.balance ? "up" : "down"

        // Openstake is a negative number, so other way around
        const openStakeTrend =
          newBalance.openStake === oldBalance.openStake
            ? null
            : newBalance.openStake < oldBalance.openStake
              ? "up"
              : "down"

        // Create updated balance with trends
        result[key] = {
          ...newBalance,
          balanceTrend,
          openStakeTrend,
        }
      })

      return result
    default:
      return state
  }
}

function userBalances(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_USER_BALANCES_SUCCEEDED:
      return {
        ...state,
        [action.accountName]: action.userBalances,
      }
    default:
      return state
  }
}

function userLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_USER_BALANCES:
      return true
    case Type.FETCH_USER_BALANCES_FAILED:
    case Type.FETCH_USER_BALANCES_SUCCEEDED:
      return false
    default:
      return state
  }
}

function backgroundRefresh(state = false, action) {
  switch (action.type) {
    case Type.START_BACKGROUND_REFRESH:
      return true
    case Type.STOP_BACKGROUND_REFRESH:
      return false
    default:
      return state
  }
}

function userBackgroundRefresh(state = null, action) {
  switch (action.type) {
    case Type.START_USER_BACKGROUND_REFRESH:
      return action.accountName
    case Type.STOP_USER_BACKGROUND_REFRESH:
      return null
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  balances,
  userLoading,
  userBalances,
  backgroundRefresh,
  userBackgroundRefresh,
})

export default reducer
