export const Type = {
  FETCH_USERS: "FETCH_USERS",
  FETCH_USERS_SUCCEEDED: "FETCH_USERS_SUCCEEDED",
  FETCH_USERS_FAILED: "FETCH_USERS_FAILED",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCEEDED: "DELETE_USER_SUCCEEDED",
  DELETE_USER_FAILED: "DELETE_USER_FAILED",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCEEDED: "CREATE_USER_SUCCEEDED",
  CREATE_USER_FAILED: "CREATE_USER_FAILED",
  ASSIGN_USER_AUTHORITIES: "ASSIGN_USER_AUTHORITIES",
  ASSIGN_USER_AUTHORITIES_SUCCEEDED: "ASSIGN_USER_AUTHORITIES_SUCCEEDED",
  ASSIGN_USER_AUTHORITIES_FAILED: "ASSIGN_USER_AUTHORITIES_FAILED",
  ASSIGN_USER_ACCOUNTS: "ASSIGN_USER_ACCOUNTS",
  ASSIGN_USER_ACCOUNTS_SUCCEEDED: "ASSIGN_USER_ACCOUNTS_SUCCEEDED",
  ASSIGN_USER_ACCOUNTS_FAILED: "ASSIGN_USER_ACCOUNTS_FAILED",
}

export function fetchUsers() {
  return { type: Type.FETCH_USERS }
}

export function fetchUsersSucceeded(users) {
  return { type: Type.FETCH_USERS_SUCCEEDED, users }
}

export function fetchUsersFailed(error) {
  return { type: Type.FETCH_USERS_FAILED, error }
}

export function deleteUser(userId) {
  return { type: Type.DELETE_USER, userId }
}

export function deleteUserSucceeded(userId) {
  return { type: Type.DELETE_USER_SUCCEEDED, userId }
}

export function deleteUserFailed(error) {
  return { type: Type.DELETE_USER_FAILED, error }
}

export function createUser(user) {
  return { type: Type.CREATE_USER, user }
}

export function createUserSucceeded(user) {
  return { type: Type.CREATE_USER_SUCCEEDED, user }
}

export function createUserFailed(error) {
  return { type: Type.CREATE_USER_FAILED, error }
}

export function assignUserAuthorities(userId, authorities) {
  return { type: Type.ASSIGN_USER_AUTHORITIES, userId, authorities }
}

export function assignUserAuthoritiesSucceeded(userId, authorities) {
  return { type: Type.ASSIGN_USER_AUTHORITIES_SUCCEEDED, userId, authorities }
}

export function assignUserAuthoritiesFailed(error) {
  return { type: Type.ASSIGN_USER_AUTHORITIES_FAILED, error }
}

export function assignUserAccounts(userId, accountNames) {
  return { type: Type.ASSIGN_USER_ACCOUNTS, userId, accountNames }
}

export function assignUserAccountsSucceeded(userId, accountNames) {
  return { type: Type.ASSIGN_USER_ACCOUNTS_SUCCEEDED, userId, accountNames }
}

export function assignUserAccountsFailed(error) {
  return { type: Type.ASSIGN_USER_ACCOUNTS_FAILED, error }
}
