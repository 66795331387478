import { store } from "./store"
import { signOutSucceeded } from "../actions/user"
import { message } from "antd"

/**
 * Post without JSON.stringify
 * @param path
 * @param body
 */
export function postRaw(path, body) {
  return apiFetch("post", path, body)
}

export function post(path, body) {
  return apiFetch("post", path, JSON.stringify(body))
}

export function get(path) {
  return apiFetch("get", path)
}

export function httpDelete(path) {
  return apiFetch("delete", path)
}

function parseJSON(response) {
  return new Promise((resolve) => {
    const contentType = response.headers.get("content-type")
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response
        .json()
        .then((json) =>
          resolve({
            status: response.status,
            ok: response.ok,
            json,
          }),
        )
        .catch((error) => resolve({ ok: false, json: error }))
    }
    return response
      .text()
      .then((text) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json: { message: text },
        }),
      )
      .catch((error) => resolve({ ok: false, json: error }))
  })
}

function apiFetch(method, path, body) {
  return new Promise((resolve, reject) => {
    return fetch(path, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin", // Allow cookies
      body: body,
    })
      .then(parseJSON)
      .then((response) => {
        if (response.status === 401) {
          if (store.getState().user.authenticated) {
            message.info("Your session ended. You were automatically logged out.", 10)
            store.dispatch(signOutSucceeded())
          }
        }

        if (response.ok) {
          return resolve(response.json)
        }
        // extract the error from the server's json
        return reject(response.json)
      })
      .catch((error) =>
        reject({
          networkError: error.message,
        }),
      )
  })
}
