import { FormattedDate, FormattedNumber } from "react-intl"

export const DateTime = ({ value, ...options }) =>
  !value ? (
    "-"
  ) : (
    <FormattedDate
      value={value}
      year="numeric"
      month="2-digit"
      day="2-digit"
      hour="2-digit"
      minute="2-digit"
      {...options}
    />
  )

export const Price = ({ value }) =>
  !value ? "-" : <FormattedNumber value={value} minimumFractionDigits={3} maximumFractionDigits={3} />

const getStyle = (value, grayedOut, colored) => {
  if (grayedOut) {
    return { color: "#8c8c8c" }
  }
  if (colored) {
    return { color: value < 0 ? "#f5222d" : "#52c41a" }
  }
  return null
}

export const Money = ({ value, ccy, grayedOut, colored, showSign = false, ...props }) =>
  !value && value !== 0 ? (
    "-"
  ) : (
    <span {...props} style={getStyle(value, grayedOut, colored)}>
      {showSign && value > 0 ? "+" : ""}
      <FormattedNumber
        value={value}
        // eslint-disable-next-line
        style="currency"
        currency={ccy}
      />
    </span>
  )

export const Edge = ({ value, grayedOut = false }) =>
  !value && value !== 0 ? (
    "-"
  ) : (
    <span style={getStyle(value, grayedOut, true)}>
      <FormattedNumber
        value={value}
        // eslint-disable-next-line
        style="percent"
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    </span>
  )

export const Percent = ({ value, grayedOut, colored, minFractionDigits = 2, maxFractionDigits = 2 }) =>
  !value && value !== 0 ? (
    "-"
  ) : (
    <span style={getStyle(value, grayedOut, colored)}>
      <FormattedNumber
        value={value}
        // eslint-disable-next-line
        style="percent"
        minimumFractionDigits={minFractionDigits}
        maximumFractionDigits={maxFractionDigits}
      />
    </span>
  )

export const OneInX = ({ pValue }) => {
  const inX = 1 / pValue
  if (inX < 1.95) {
    return (
      <>
        1 in <FormattedNumber value={inX} minimumFractionDigits={1} maximumFractionDigits={1} />
      </>
    )
  } else {
    return <>1 in {Math.round(inX)}</>
  }
}
