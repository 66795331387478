import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import _ from "lodash"
import {
  Type,
  fetchOrdersSucceeded,
  fetchOrdersFailed,
  fetchOrderPriceGraphSucceeded,
  fetchOrderPriceGraphFailed,
  fetchModelPriceGraphSucceeded,
  fetchModelPriceGraphFailed,
  fetchFixtureGraphSucceeded,
  fetchFixtureGraphFailed,
  fetchRunningballGraphSucceeded,
  fetchRunningballGraphFailed,
  fetchOrders as fetchOrders_1,
  fetchValuesSucceeded,
  fetchValuesFailed,
  fetchOrderChildrenSucceeded,
  fetchOrderChildrenFailed,
  fetchFlagsSucceeded,
  fetchFlagsFailed,
} from "../actions/orders"
import { get, post, postRaw } from "../lib/fetch"
import { convertFilter } from "./ordersFilter"

function* watchFetchOrders() {
  return yield takeLatest(Type.FETCH_ORDERS, fetchOrders)
}

function* fetchOrders(action) {
  try {
    const table = yield select((state) => state.orders.table)
    let filter = yield select((state) => convertFilter(state.orders.filterFields))

    let includeBetslips = yield select((state) => state.orders.includeBetslips)

    filter = {
      ...filter,
      ...action.fixedFilter,
    }

    const current = table.pagination?.current || 1
    const pageSize = table.pagination?.pageSize || 20

    const page = yield call(
      post,
      `/api/trading/orders?page=${current}&pageSize=${pageSize}&includeBetslips=${includeBetslips}`,
      filter,
    )
    yield put(fetchOrdersSucceeded(page))
  } catch (e) {
    yield put(fetchOrdersFailed(e))
  }
}

// ---

function* watchFetchOrderPriceGraph() {
  return yield takeEvery(Type.FETCH_ORDER_PRICE_GRAPH, fetchOrderPriceGraph)
}

function* fetchOrderPriceGraph(action) {
  try {
    const page = yield call(get, `/api/trading/orders/${action.betslipUuid}/price-graph?bookie=${action.bookie}`)
    yield put(fetchOrderPriceGraphSucceeded(page, action.bookie))
  } catch (e) {
    yield put(fetchOrderPriceGraphFailed(e))
  }
}

// ---

function* watchFetchModelPriceGraph() {
  return yield takeEvery(Type.FETCH_MODEL_PRICE_GRAPH, fetchModelPriceGraph)
}

function* fetchModelPriceGraph(action) {
  try {
    let url = `/api/trading/orders/${action.betslipUuid}/model-graph`
    const query = []
    if (typeof action.homeScoreRate === "number") {
      query.push(`homeScoreRate=${action.homeScoreRate}`)
    }
    if (typeof action.awayScoreRate === "number") {
      query.push(`awayScoreRate=${action.awayScoreRate}`)
    }
    if (typeof action.nu === "number") {
      query.push(`nu=${action.nu}`)
    }
    if (typeof action.zeroAdjustment === "number") {
      query.push(`zeroAdjustment=${action.zeroAdjustment}`)
    }
    if (query.length > 0) {
      url += `?${query.join("&")}`
    }
    const values = yield call(get, url)
    yield put(fetchModelPriceGraphSucceeded(values))
  } catch (e) {
    yield put(fetchModelPriceGraphFailed(e))
  }
}

// ---

function* watchFetchFixtureGraph() {
  return yield takeLatest(Type.FETCH_FIXTURE_GRAPH, fetchFixtureGraph)
}

function* fetchFixtureGraph(action) {
  try {
    const page = yield call(get, `/api/trading/orders/${action.betslipUuid}/fixture-graph`)
    yield put(fetchFixtureGraphSucceeded(page))
  } catch (e) {
    yield put(fetchFixtureGraphFailed(e))
  }
}

// ---

function* watchFetchRunningballGraph() {
  return yield takeLatest(Type.FETCH_RUNNINGBALL_GRAPH, fetchRunningballGraph)
}

function* fetchRunningballGraph(action) {
  try {
    const page = yield call(get, `/api/trading/orders/${action.betslipUuid}/runningball-events`)
    yield put(fetchRunningballGraphSucceeded(page))
  } catch (e) {
    yield put(fetchRunningballGraphFailed(e))
  }
}

// ---

function* backgroundRefresh(action) {
  const { fixedFilter } = action
  while (true) {
    const refresh = yield select((state) => state.orders.backgroundRefresh)
    if (!refresh) {
      break
    }
    yield put(fetchOrders_1(fixedFilter))
    yield delay(5000)
  }
}

function* watchStartBackgroundRefresh() {
  yield takeLatest(Type.START_BACKGROUND_REFRESH, backgroundRefresh)
}

// ---

function* watchFetchValues() {
  return yield takeLatest(Type.FETCH_VALUES, fetchValues)
}

function* fetchValues(action) {
  const field = action.field
  const fieldUrl = _.kebabCase(field)
  try {
    const values = yield call(post, `/api/trading/orders/values/${fieldUrl}`, action.fixedFilter)
    yield put(fetchValuesSucceeded(field, values, action.fixedFilter))
  } catch (e) {
    yield put(fetchValuesFailed(field, e, action.fixedFilter))
  }
}

// ---

function* watchFetchOrderChildren() {
  return yield takeLatest(Type.FETCH_ORDER_CHILDREN, fetchOrderChildren)
}

function* fetchOrderChildren(action) {
  try {
    const orderId = action.orderId

    const orders = yield call(get, `/api/trading/orders/${orderId}/children`)
    yield put(fetchOrderChildrenSucceeded(orders))
  } catch (e) {
    yield put(fetchOrderChildrenFailed(e))
  }
}

// ---

function* watchSetOrderValid() {
  return yield takeEvery(Type.SET_ORDER_VALID, setOrderValid)
}

function* setOrderValid(action) {
  try {
    const { betslipUuid, valid } = action

    yield call(postRaw, `/api/trading/orders/${betslipUuid}/valid`, valid)
  } catch (e) {
    console.error(e)
  }
}

// ---

function* watchFetchFlags() {
  return yield takeLatest(Type.FETCH_FLAGS, fetchFlags)
}

function* fetchFlags(action) {
  try {
    const { fixedFilter } = action

    const flags = yield call(post, `/api/trading/orders/values/flags`, fixedFilter)
    yield put(fetchFlagsSucceeded(flags))
  } catch (e) {
    yield put(fetchFlagsFailed(e))
  }
}

// ---

export default function* ordersSaga() {
  yield all([
    watchFetchOrders(),
    watchFetchOrderPriceGraph(),
    watchFetchModelPriceGraph(),
    watchFetchFixtureGraph(),
    watchFetchRunningballGraph(),
    watchFetchOrderChildren(),
    watchStartBackgroundRefresh(),
    watchFetchValues(),
    watchSetOrderValid(),
    watchFetchFlags(),
  ])
}
