export const Type = {
  FETCH_BOTS: "bots/FETCH_BOTS",
  FETCH_BOTS_SUCCEEDED: "bots/FETCH_BOTS_SUCCEEDED",
  FETCH_BOTS_FAILED: "bots/FETCH_BOTS_FAILED",
  BOT_SELECTED: "bots/BOT_SELECTED",
  DELETE_INSTANCE: "bots/DELETE_INSTANCE",
  DELETE_INSTANCE_FAILED: "bots/DELETE_INSTANCE_FAILED",
  DELETE_BOT: "bots/DELETE_BOT",
  DELETE_BOT_FAILED: "bots/DELETE_BOT_FAILED",
}

export function fetchBots() {
  return { type: Type.FETCH_BOTS }
}

export function fetchBotsSucceeded(page) {
  return { type: Type.FETCH_BOTS_SUCCEEDED, page }
}

export function fetchBotsFailed(error) {
  return { type: Type.FETCH_BOTS_FAILED, error }
}

export function botSelected(bot) {
  return { type: Type.BOT_SELECTED, bot }
}

export function deleteInstance(principalId, accountName, hostName) {
  return { type: Type.DELETE_INSTANCE, principalId, accountName, hostName }
}

export function deleteInstanceFailed(error) {
  return { type: Type.DELETE_INSTANCE_FAILED, error }
}

export function deleteBot(principalId, accountName) {
  return { type: Type.DELETE_BOT, principalId, accountName }
}

export function deleteBotFailed(error) {
  return { type: Type.DELETE_BOT_FAILED, error }
}
