import { DownOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { Avatar, Button, Dropdown } from "antd"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { signOut, toggleDiscretionMode } from "../actions/user"
import "../less/UserMenu.less"

const UserMenu = () => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.user.profile)
  const discretionMode = useSelector(state => state.user.discretionMode)

  const handleSignOut = useCallback(() => {
    dispatch(signOut())
  }, [dispatch])

  const handleToggleDiscretionMode = useCallback(() => {
    dispatch(toggleDiscretionMode())
  }, [dispatch])

  const menuItems = [
    {
      key: "profile",
      label: profile.name,
    },
    {
      key: "divider",
      type: "divider",
    },
    {
      key: "discretion",
      label: (
        <Button size="small" onClick={handleToggleDiscretionMode} type="default">
          {discretionMode ? <EyeInvisibleOutlined /> : <EyeOutlined />} Discretion
        </Button>
      ),
    },
    {
      key: "signout",
      label: (
        <Button size="small" block type="danger" ghost onClick={handleSignOut}>
          Sign Out
        </Button>
      ),
    },
  ]

  return (
    <div className="userMenu">
      <Dropdown trigger={["click"]} menu={{ items: menuItems }}>
        <div style={{ cursor: "pointer" }}>
          <Avatar className="avatar">{(profile.givenName || profile.name || "U")[0]}</Avatar>
          <DownOutlined style={{ marginLeft: "5px" }} />
        </div>
      </Dropdown>
      {/*<span className='name'>{profile.name}</span>*/}
    </div>
  )
}

export default UserMenu
