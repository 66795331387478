import { all, call, put, select, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchDuplicateTeamMatchesSucceeded,
  fetchDuplicateTeamMatchesFailed,
  fetchTeamMatchSucceeded,
  fetchTeamMatchFailed,
  deleteTeamMatchSucceeded,
  closeTeamMatch,
  deleteTeamMatchFailed,
  fetchTeamMatchesSucceeded,
  fetchTeamMatchesFailed,
  fetchCompetitionMatchesSucceeded,
  fetchCompetitionMatchesFailed,
  fetchCompetitionMatchSucceeded,
  fetchCompetitionMatchFailed,
} from "../actions/betexplorer"
import { get, httpDelete, postRaw } from "../lib/fetch"

function* watchFetchDuplicateTeamMatches() {
  return yield takeLatest(Type.FETCH_DUPLICATE_TEAM_MATCHES, fetchDuplicateTeamMatches)
}

function* fetchDuplicateTeamMatches() {
  try {
    const duplicateTeamMatches = yield call(get, `/api/betexplorer-matching/matches/teams/duplicates`)
    yield put(fetchDuplicateTeamMatchesSucceeded(duplicateTeamMatches))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchDuplicateTeamMatchesFailed(e))
  }
}

// ---

function* watchFetchTeamMatch() {
  return yield takeLatest(Type.FETCH_TEAM_MATCH, fetchTeamMatch)
}

function* fetchTeamMatch(action) {
  try {
    const { teamId } = action
    const teamMatch = yield call(get, `/api/betexplorer-matching/matches/teams/${teamId}`)
    yield put(fetchTeamMatchSucceeded(teamMatch))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchTeamMatchFailed(e))
  }
}

// ---

function* watchDeleteTeamMatch() {
  return yield takeLatest(Type.DELETE_TEAM_MATCH, deleteTeamMatch)
}

function* deleteTeamMatch(action) {
  try {
    const { teamId, betExplorerId } = action
    yield call(httpDelete, `/api/betexplorer-matching/matches/teams/${teamId}/${betExplorerId}`)
    yield put(deleteTeamMatchSucceeded(teamId))
    yield put(closeTeamMatch())
  } catch (e) {
    console.log("Error", e)
    yield put(deleteTeamMatchFailed(e))
  }
}

// ---

function* watchFetchTeamMatches() {
  return yield takeLatest(Type.FETCH_TEAM_MATCHES, fetchTeamMatches)
}

function* fetchTeamMatches() {
  try {
    const table = yield select((state) => state.betexplorer.teamMatchesTable)
    const filter = yield select((state) => state.betexplorer.teamMatchesFilter)

    const current = table.pagination?.current || 1
    const pageSize = table.pagination?.pageSize || 20

    const page = yield call(
      postRaw,
      `/api/betexplorer-matching/matches/teams?page=${current}&pageSize=${pageSize}`,
      filter || undefined,
    )
    yield put(fetchTeamMatchesSucceeded(page))
  } catch (e) {
    yield put(fetchTeamMatchesFailed(e))
  }
}

// ---

function* watchFetchCompetitionMatches() {
  return yield takeLatest(Type.FETCH_COMPETITION_MATCHES, fetchCompetitionMatches)
}

function* fetchCompetitionMatches() {
  try {
    const table = yield select((state) => state.betexplorer.competitionMatchesTable)
    const filter = yield select((state) => state.betexplorer.competitionMatchesFilter)

    const current = table.pagination?.current || 1
    const pageSize = table.pagination?.pageSize || 20

    const page = yield call(
      postRaw,
      `/api/betexplorer-matching/matches/competitions?page=${current}&pageSize=${pageSize}`,
      filter || undefined,
    )
    yield put(fetchCompetitionMatchesSucceeded(page))
  } catch (e) {
    yield put(fetchCompetitionMatchesFailed(e))
  }
}

// ---

function* watchFetchCompetitionMatch() {
  return yield takeLatest(Type.FETCH_COMPETITION_MATCH, fetchCompetitionMatch)
}

function* fetchCompetitionMatch(action) {
  try {
    const { competitionId } = action
    const competitionMatch = yield call(get, `/api/betexplorer-matching/matches/competitions/${competitionId}`)
    yield put(fetchCompetitionMatchSucceeded(competitionMatch))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchCompetitionMatchFailed(e))
  }
}

// ---

export default function* betExplorerSaga() {
  yield all([
    watchFetchDuplicateTeamMatches(),
    watchFetchTeamMatch(),
    watchDeleteTeamMatch(),
    watchFetchTeamMatches(),
    watchFetchCompetitionMatches(),
    watchFetchCompetitionMatch(),
  ])
}
