import { combineReducers } from "redux"
import { Type } from "../actions/model"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_MODEL:
      return true
    case Type.FETCH_MODEL_FAILED:
    case Type.FETCH_MODEL_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_MODEL_FAILED:
      return action.error
    case Type.FETCH_MODEL:
      return null
    default:
      return state
  }
}

function model(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_MODEL_SUCCEEDED:
      return action.model
    case Type.FETCH_MODEL_FAILED:
      return {}
    default:
      return state
  }
}

function form(state = {}, action) {
  switch (action.type) {
    case Type.FORM_CHANGED:
      return action.form
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  model,
  form,
})

export default reducer
