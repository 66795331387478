export const Type = {
  OAUTH_SUCCEEDED: "OAUTH_SUCCEEDED",
  OAUTH_FAILED: "OAUTH_FAILED",
  SIGN_IN_SUCCEEDED: "SIGN_IN_SUCCEEDED",
  SIGN_IN_FAILED: "SIGN_IN_FAILED",
  SIGN_OUT: "SIGN_OUT",
  SIGN_OUT_SUCCEEDED: "SIGN_OUT_SUCCEEDED",
  SIGN_OUT_FAILED: "SIGN_OUT_FAILED",
  FETCH_MYSELF: "FETCH_MYSELF",
  FETCH_MYSELF_SUCCEEDED: "FETCH_MYSELF_SUCCEEDED",
  FETCH_MYSELF_FAILED: "FETCH_MYSELF_FAILED",
  TOGGLE_DISCRETION_MODE: "TOGGLE_DISCRETION_MODE",
}

export function oAuthSucceeded(res) {
  return { type: Type.OAUTH_SUCCEEDED, res }
}

export function oAuthFailed(res) {
  return { type: Type.OAUTH_FAILED, res }
}

export function signInSucceeded(res) {
  return { type: Type.SIGN_IN_SUCCEEDED, res }
}

export function signInFailed(error) {
  return { type: Type.SIGN_IN_FAILED, error }
}

export function signOut() {
  return { type: Type.SIGN_OUT }
}

export function signOutSucceeded() {
  return { type: Type.SIGN_OUT_SUCCEEDED }
}

export function signOutFailed(error) {
  return { type: Type.SIGN_OUT_FAILED, error }
}

export function fetchMyself() {
  return { type: Type.FETCH_MYSELF }
}

export function fetchMyselfSucceeded(myself) {
  return { type: Type.FETCH_MYSELF_SUCCEEDED, myself }
}

export function fetchMyselfFailed(error) {
  return { type: Type.FETCH_MYSELF_FAILED, error }
}

export function toggleDiscretionMode() {
  return { type: Type.TOGGLE_DISCRETION_MODE }
}
