import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { Type, fetchAnalyticsSucceeded, fetchAnalyticsFailed } from "../actions/analytics"
import { post } from "../lib/fetch"
import { convertFilter } from "./ordersFilter"

function* watchFetchAnalytics() {
  return yield takeLatest(Type.FETCH_ANALYTICS, fetchAnalytics)
}

function* fetchAnalytics(action) {
  try {
    const groupBy = yield select((state) => state.analytics.groupBy)
    let filter = yield select((state) => convertFilter(state.orders.filterFields))
    let includeBetslips = yield select((state) => state.orders.includeBetslips)

    filter = {
      ...filter,
      ...action.fixedFilter,
    }

    const analytics = yield call(
      post,
      `/api/trading/orders/analytics?includeBetslips=${includeBetslips}` +
        (groupBy ? `&groupBy=${groupBy[0]}(${groupBy[1] || ""})` : ""),
      filter,
    )

    yield put(fetchAnalyticsSucceeded(analytics))
  } catch (e) {
    yield put(fetchAnalyticsFailed(e))
  }
}

export default function* analyticsSaga() {
  yield all([watchFetchAnalytics()])
}
