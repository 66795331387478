export const Type = {
  FETCH_STATUS: "broker/FETCH_STATUS",
  FETCH_STATUS_SUCCEEDED: "broker/FETCH_STATUS_SUCCEEDED",
  FETCH_STATUS_FAILED: "broker/FETCH_STATUS_FAILED",
  START_STATUS_BACKGROUND_REFRESH: "broker/START_STATUS_BACKGROUND_REFRESH",
  STOP_STATUS_BACKGROUND_REFRESH: "broker/STOP_STATUS_BACKGROUND_REFRESH",
}

export function fetchStatus(accountName) {
  return { type: Type.FETCH_STATUS, accountName }
}

export function fetchStatusSucceeded(accountName, status) {
  return { type: Type.FETCH_STATUS_SUCCEEDED, accountName, status }
}

export function fetchStatusFailed(error) {
  return { type: Type.FETCH_STATUS_FAILED, error }
}

export function startStatusBackgroundRefresh(accountName) {
  return { type: Type.START_STATUS_BACKGROUND_REFRESH, accountName }
}

export function stopStatusBackgroundRefresh(accountName) {
  return { type: Type.STOP_STATUS_BACKGROUND_REFRESH, accountName }
}
