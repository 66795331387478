import { combineReducers } from "redux"
import { Type } from "../actions/runningball"
import dayjs from "dayjs"

function sportsTickersDate(state = dayjs().format("YYYY-MM-DD"), action) {
  switch (action.type) {
    case Type.SET_SPORTS_TICKERS_DATE:
      return action.date
    default:
      return state
  }
}

function sportsTickersLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_SPORTS_TICKERS:
      return true
    case Type.FETCH_SPORTS_TICKERS_SUCCEEDED:
    case Type.FETCH_SPORTS_TICKERS_FAILED:
      return false
    default:
      return state
  }
}

function sportsTickersError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_SPORTS_TICKERS_FAILED:
      return action.error
    case Type.FETCH_SPORTS_TICKERS:
      return null
    default:
      return state
  }
}

function sportsTickers(state = [], action) {
  switch (action.type) {
    case Type.FETCH_SPORTS_TICKERS_SUCCEEDED:
      return action.tickers
    case Type.FETCH_SPORTS_TICKERS_FAILED:
      return []
    default:
      return state
  }
}

function sportsTickerLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_SPORTS_TICKER:
      return true
    case Type.FETCH_SPORTS_TICKER_SUCCEEDED:
    case Type.FETCH_SPORTS_TICKER_FAILED:
      return false
    default:
      return state
  }
}

function sportsTickerError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_SPORTS_TICKER_FAILED:
      return action.error
    case Type.FETCH_SPORTS_TICKER:
      return null
    default:
      return state
  }
}

function sportsTicker(state = null, action) {
  switch (action.type) {
    case Type.FETCH_SPORTS_TICKER_SUCCEEDED:
      return action.ticker
    case Type.FETCH_SPORTS_TICKER:
    case Type.FETCH_SPORTS_TICKER_FAILED:
      return null
    default:
      return state
  }
}

function competitionsLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITIONS:
      return true
    case Type.FETCH_COMPETITIONS_SUCCEEDED:
    case Type.FETCH_COMPETITIONS_FAILED:
      return false
    default:
      return state
  }
}

function competitionsError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_COMPETITIONS_FAILED:
      return action.error
    case Type.FETCH_COMPETITIONS:
      return null
    default:
      return state
  }
}

function competitions(state = [], action) {
  switch (action.type) {
    case Type.FETCH_COMPETITIONS_SUCCEEDED:
      return action.competitions
    case Type.FETCH_COMPETITIONS:
    case Type.FETCH_COMPETITIONS_FAILED:
      return []
    case Type.SET_COMPETITION_LEVEL_SUCCEEDED:
      const countryIx = state.findIndex((s) => s.key === action.countryKey)
      if (countryIx === -1) return state

      const newState = JSON.parse(JSON.stringify(state))
      const competitionIx = newState[countryIx].competitions.findIndex((c) => c.id === action.id)
      if (competitionIx === -1) return state

      newState[countryIx].competitions[competitionIx].level = action.level
      return newState
    default:
      return state
  }
}

function competitionsSelectedCountry(state = null, action) {
  switch (action.type) {
    case Type.COMPETITIONS_COUNTRY_SELECTED:
      return action.country
    default:
      return state
  }
}

function setCompetitionLevelLoading(state = [], action) {
  switch (action.type) {
    case Type.SET_COMPETITION_LEVEL:
      if (!state.includes(action.id)) {
        return [...state, action.id]
      }
      return state
    case Type.SET_COMPETITION_LEVEL_FAILED:
    case Type.SET_COMPETITION_LEVEL_SUCCEEDED:
      return state.filter((id) => id !== action.id)
    default:
      return state
  }
}

const reducer = combineReducers({
  sportsTickersDate,
  sportsTickersLoading,
  sportsTickersError,
  sportsTickers,
  sportsTickerLoading,
  sportsTickerError,
  sportsTicker,
  competitionsLoading,
  competitionsError,
  competitions,
  competitionsSelectedCountry,
  setCompetitionLevelLoading,
})

export default reducer
