import { all, call, delay, put, select, takeLatest } from "redux-saga/effects"
import { Type, fetchStatusSucceeded, fetchStatusFailed, fetchStatus as fetchStatus_1 } from "../actions/broker"
import { get } from "../lib/fetch"

function* watchFetchStatus() {
  return yield takeLatest(Type.FETCH_STATUS, fetchStatus)
}

function* fetchStatus(action) {
  const { accountName } = action
  try {
    const status = yield call(get, `/api/broker/status?accountName=${accountName}`)
    yield put(fetchStatusSucceeded(accountName, status))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchStatusFailed(e))
  }
}

// ---

function* statusBackgroundRefresh() {
  while (true) {
    const accountName = yield select((state) => state.broker.accountNameBackgroundRefresh)
    if (accountName === null) {
      break
    }
    yield put(fetchStatus_1(accountName))
    yield delay(5000)
  }
}

function* watchStartStatusBackgroundRefresh() {
  yield takeLatest(Type.START_STATUS_BACKGROUND_REFRESH, statusBackgroundRefresh)
}

// ---

export default function* brokerSaga() {
  yield all([watchFetchStatus(), watchStartStatusBackgroundRefresh()])
}
