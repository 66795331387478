import { useEffect } from "react"
import { IntlProvider } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { fetchMyself } from "../actions/user"
import Login from "../components/Login"
import PageLoading from "../components/PageLoading"
import dayjs from "dayjs"
import "dayjs/locale/en-gb"

const Authenticated = ({ children }) => {
  const dispatch = useDispatch()
  const authenticated = useSelector((state) => state.user.authenticated)

  useEffect(() => {
    dispatch(fetchMyself())
  }, [dispatch])

  // Set dayjs locale globally
  dayjs.locale("en-gb")

  if (authenticated === null) {
    return <PageLoading />
  }

  if (authenticated) {
    return <IntlProvider locale={"en-GB"}>{children}</IntlProvider>
  }

  return <Login />
}

export default Authenticated
