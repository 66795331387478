import { all, call, put, select, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchOverviewStatsSucceeded,
  fetchOverviewStatsFailed,
  fetchOverviewGraphSucceeded,
  fetchOverviewGraphFailed,
} from "../actions/overview"
import { post } from "../lib/fetch"

function* watchFetchOverviewStats() {
  return yield takeLatest(Type.FETCH_OVERVIEW_STATS, fetchOverviewStats)
}

function* fetchOverviewStats(action) {
  try {
    const selectedBots = yield select((state) => state.overview.selectedBots[action.accountId])
    const page = yield call(post, "/api/trading/orders/analytics", {
      placers: [action.accountId],
      principalIds: selectedBots,
      status: ["SETTLED"],
    })

    yield put(fetchOverviewStatsSucceeded(page))
  } catch (e) {
    yield put(fetchOverviewStatsFailed(e))
  }
}

// ---

function* watchFetchOverviewGraph() {
  return yield takeLatest(Type.FETCH_OVERVIEW_GRAPH, fetchOverviewGraph)
}

function* fetchOverviewGraph(action) {
  try {
    const selectedBots = yield select((state) => state.overview.selectedBots[action.accountId])
    const byTime = yield select((state) => state.dashboard.byTime)

    const filter = {
      placers: [action.accountId],
      principalIds: selectedBots,
      status: ["SETTLED"],
    }

    let page
    if (byTime) {
      page = yield call(post, `/api/trading/orders/analytics?groupBy=fixtureStartTimestamp(auto)`, filter)
    } else {
      page = yield call(post, `/api/trading/orders/analytics?groupBy=orderId()`, filter)
    }

    yield put(fetchOverviewGraphSucceeded(page))
  } catch (e) {
    yield put(fetchOverviewGraphFailed(e))
  }
}

export default function* overviewSaga() {
  yield all([watchFetchOverviewStats(), watchFetchOverviewGraph()])
}
