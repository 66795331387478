import { Menu } from "antd"
import { Link, useLocation } from "react-router"
import { connect } from "react-redux"

const TopMenu = ({ authorities }) => {
  const location = useLocation()
  const split = location.pathname.split("/")
  let selectedKey = location.pathname
  if (split.length >= 2) {
    selectedKey = split[1]
  }

  // Create menu items array
  const menuItems = []

  // Add Game Center item if user has the role
  if (authorities.includes("ROLE_GAME_CENTER")) {
    menuItems.push({
      key: "/game-center",
      label: <Link to="/game-center">Game Center</Link>,
    })
  }

  // Add Bots item (available to all)
  menuItems.push({
    key: "/bots",
    label: <Link to="/bots/overview">Bots</Link>,
  })

  // Add Maritimo item if user has the role
  if (authorities.includes("ROLE_BACKTESTING")) {
    menuItems.push({
      key: "/backtesting",
      label: <Link to="/backtesting">Maritimo</Link>,
    })
  }

  // Add Data Sources item if user has the role
  if (authorities.includes("ROLE_DATA_SOURCES")) {
    menuItems.push({
      key: "/data-sources",
      label: <Link to="/data-sources">Data Sources</Link>,
    })
  }

  // Add Users item if user has admin role
  if (authorities.includes("ROLE_ADMIN")) {
    menuItems.push({
      key: "/users",
      label: <Link to="/users">Users</Link>,
    })
  }

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      className="topMenu"
      selectedKeys={[`/${selectedKey}`]}
      style={{ lineHeight: "63px" }}
      items={menuItems}
    />
  )
}

function mapStateToProps(state) {
  return {
    authorities: state.user.profile.authorities || [],
  }
}

export default connect(mapStateToProps)(TopMenu)
