import { createRoot } from "react-dom/client"
import App from "./App"
import { store } from "./lib/store"
import { BrowserRouter } from "react-router"
import { Provider } from "react-redux"
import Authenticated from "./pages/_authenticated"
import AppThemeProvider from "./theme/AppThemeProvider"
import "./less/App.less"
import "@ant-design/v5-patch-for-react-19"

const root = createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    <AppThemeProvider>
      <Authenticated>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Authenticated>
    </AppThemeProvider>
  </Provider>,
)
