// Ant Design theme configuration
const themeConfig = {
  token: {
    // Global token configuration
    fontSize: 13,
  },
  components: {
    Tag: {
      fontSizeSM: 12,
    
    },
  },
}

export default themeConfig
