export const Type = {
  FETCH_MODEL: "model/FETCH_MODEL",
  FETCH_MODEL_SUCCEEDED: "model/FETCH_MODEL_SUCCEEDED",
  FETCH_MODEL_FAILED: "model/FETCH_MODEL_FAILED",
  FORM_CHANGED: "model/FORM_CHANGED",
}

export function fetchModel(values) {
  return { type: Type.FETCH_MODEL, values }
}

export function fetchModelSucceeded(model) {
  return { type: Type.FETCH_MODEL_SUCCEEDED, model }
}

export function fetchModelFailed(error) {
  return { type: Type.FETCH_MODEL_FAILED, error }
}

export function formChanged(form) {
  return { type: Type.FORM_CHANGED, form }
}
