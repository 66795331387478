import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { startBackgroundRefresh, stopBackgroundRefresh } from "../../actions/balances"
import "../../less/Balances.less"
import Discretion from "../Discretion"
import { TrendingMoney } from "../TrendingMoney"
import { Link } from "react-router"
import { LineChartOutlined, UnorderedListOutlined } from "@ant-design/icons"

const Balances = () => {
  const dispatch = useDispatch()
  const balances = useSelector((state) => state.balances.balances)
  const authorities = useSelector((state) => state.user.profile.authorities || [])

  useEffect(() => {
    dispatch(startBackgroundRefresh())
    
    return () => {
      dispatch(stopBackgroundRefresh())
    }
  }, [dispatch])

  return (
    <div className="balances">
      {Object.entries(balances || {}).map(([account, balance]) => {
        return (
          <div className="accountBalance" key={account}>
            <div className="account">
              {balance.hasDetailedBalances ? (
                <Link to={`/balances/${account}`}>
                  <Discretion>{account}</Discretion>
                </Link>
              ) : (
                <Discretion>{account}</Discretion>
              )}{" "}
              {authorities.includes("ROLE_TRADING_PERFORMANCE") && (
                <Link to={`/trading-performance/${account}`}>
                  <LineChartOutlined />
                </Link>
              )}
            </div>

            <TrendingMoney
              value={balance.balance}
              ccy={balance.ccy}
              trend={balance.balanceTrend}
              className="balance"
            />

            {/*<TrendingMoney*/}
            {/*  value={balance.credit}*/}
            {/*  ccy={balance.ccy}*/}
            {/*  trend={balance.balanceTrend}*/}
            {/*  className='credit'*/}
            {/*>credit </TrendingMoney>*/}

            <TrendingMoney
              value={Math.abs(balance.openStake)}
              ccy={balance.ccy}
              trend={balance.openStakeTrend}
              className="openStake"
            >
              open{" "}
            </TrendingMoney>
          </div>
        )
      })}
    </div>
  )
}

export default Balances
