import { all, call, delay, put, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchBotsSucceeded,
  fetchBotsFailed,
  fetchBots as fetchBots_1,
  deleteInstanceFailed,
  deleteBotFailed,
} from "../actions/bots"
import { get, httpDelete } from "../lib/fetch"

function* watchFetchBots() {
  return yield takeLatest(Type.FETCH_BOTS, fetchBots)
}

function* fetchBots(action) {
  try {
    const page = yield call(get, `/api/trading/trading-bots`)

    yield put(fetchBotsSucceeded(page))
  } catch (e) {
    yield put(fetchBotsFailed(e))
  }
}

// ---

function* watchDeleteInstance() {
  return yield takeLatest(Type.DELETE_INSTANCE, deleteInstance)
}

function* deleteInstance(action) {
  try {
    const { principalId, accountName, hostName } = action
    yield call(
      httpDelete,
      `/api/trading/trading-bots?principalId=${principalId}&accountName=${accountName}&hostName=${hostName}`,
    )

    yield delay(1000)
    yield put(fetchBots_1())
  } catch (e) {
    yield put(deleteInstanceFailed(e))
  }
}

// ---

function* watchDeleteBot() {
  return yield takeLatest(Type.DELETE_BOT, deleteBot)
}

function* deleteBot(action) {
  try {
    const { principalId, accountName } = action
    yield call(httpDelete, `/api/trading/trading-bots/delete?principalId=${principalId}&accountName=${accountName}`)

    yield delay(1000)
    yield put(fetchBots_1())
  } catch (e) {
    yield put(deleteBotFailed(e))
  }
}

// ---

export default function* botsSaga() {
  yield all([watchFetchBots(), watchDeleteInstance(), watchDeleteBot()])
}
