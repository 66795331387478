import { combineReducers } from "redux"
import { Type } from "../actions/dashboard"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_STATS:
      return true
    case Type.FETCH_DASHBOARD_STATS_FAILED:
    case Type.FETCH_DASHBOARD_STATS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_STATS_FAILED:
      return action.error
    case Type.FETCH_DASHBOARD_STATS:
      return null
    default:
      return state
  }
}

function dashboardStats(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_STATS_SUCCEEDED:
      return action.page
    default:
      return state
  }
}

function dashboardGraphLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_GRAPH:
      return true
    case Type.FETCH_DASHBOARD_GRAPH_FAILED:
    case Type.FETCH_DASHBOARD_GRAPH_SUCCEEDED:
      return false
    default:
      return state
  }
}

function dashboardGraph(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_GRAPH_SUCCEEDED:
      return action.page
    default:
      return state
  }
}

function byTime(state = true, action) {
  switch (action.type) {
    case Type.TOGGLE_DASHBOARD_GRAPH_BY_TIME:
      return !state
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  dashboardStats,
  dashboardGraph,
  dashboardGraphLoading,
  byTime,
})

export default reducer
