import { combineReducers } from "redux"
import { Type } from "../actions/analytics"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_ANALYTICS:
      return true
    case Type.FETCH_ANALYTICS_FAILED:
    case Type.FETCH_ANALYTICS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_ANALYTICS_FAILED:
      return action.error
    case Type.FETCH_ANALYTICS:
      return null
    default:
      return state
  }
}

function analytics(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_ANALYTICS_SUCCEEDED:
      // Create a new object with reversed buckets
      const result = { ...action.analytics }
      if (result.buckets && Array.isArray(result.buckets)) {
        result.buckets = [...result.buckets].reverse() // Newest bucket on top
      }
      return result
    case Type.FETCH_ANALYTICS_FAILED:
      return {}
    default:
      return state
  }
}

function groupBy(state = null, action) {
  switch (action.type) {
    case Type.SET_GROUP_BY:
      return !action.groupBy || action.groupBy[0] === "none" ? null : action.groupBy
    default:
      return state
  }
}

function flatStaking(state = false, action) {
  switch (action.type) {
    case Type.SET_FLAT_STAKING:
      return action.flatStaking
    default:
      return state
  }
}

function includeCommission(state = true, action) {
  switch (action.type) {
    case Type.SET_INCLUDE_COMMISSION:
      return action.includeCommission
    default:
      return state
  }
}

function additionalColumns(state = false, action) {
  switch (action.type) {
    case Type.SET_ADDITIONAL_COLUMNS:
      return action.additionalColumns
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  analytics,
  groupBy,
  flatStaking,
  includeCommission,
  additionalColumns,
})

export default reducer
