import { all, call, put, select, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchSportsTickersSucceeded,
  fetchSportsTickersFailed,
  fetchSportsTickerSucceeded,
  fetchSportsTickerFailed,
  fetchCompetitionsSucceeded,
  fetchCompetitionsFailed,
  setCompetitionLevelSucceeded,
  setCompetitionLevelFailed,
} from "../actions/runningball"
import { get, post } from "../lib/fetch"
import { message } from "antd"

function* watchFetchSportsTickers() {
  return yield takeLatest(Type.FETCH_SPORTS_TICKERS, fetchSportsTickers)
}

function* fetchSportsTickers(action) {
  const date = yield select((state) => state.runningball.sportsTickersDate)
  try {
    const tickers = yield call(get, `/api/runningball/sports-tickers?date=${date.format("YYYY-MM-DD")}`)
    yield put(fetchSportsTickersSucceeded(tickers))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchSportsTickersFailed(e))
  }
}

// ---

function* watchFetchSportsTicker() {
  return yield takeLatest(Type.FETCH_SPORTS_TICKER, fetchSportsTicker)
}

function* fetchSportsTicker(action) {
  const { id } = action
  try {
    const ticker = yield call(get, `/api/runningball/sports-tickers/${id}`)
    yield put(fetchSportsTickerSucceeded(ticker))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchSportsTickerFailed(e))
  }
}

// ---

function* watchFetchCompetitions() {
  return yield takeLatest(Type.FETCH_COMPETITIONS, fetchCompetitions)
}

function* fetchCompetitions() {
  try {
    const competitions = yield call(get, `/api/runningball/competitions`)
    yield put(fetchCompetitionsSucceeded(competitions))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchCompetitionsFailed(e))
  }
}

// ---

function* watchSetCompetitionLevel() {
  return yield takeLatest(Type.SET_COMPETITION_LEVEL, setCompetitionLevel)
}

function* setCompetitionLevel(action) {
  const { countryKey, id, level } = action
  try {
    yield call(post, `/api/runningball/competitions/level`, { id, level })
    message.success("Level saved", 5)
    yield put(setCompetitionLevelSucceeded(countryKey, id, level))
  } catch (e) {
    yield put(setCompetitionLevelFailed(countryKey, id, level))
    message.error(e.message, 5)
    console.log("Error", e)
  }
}

// ---

export default function* runningballSaga() {
  yield all([watchFetchSportsTickers(), watchFetchSportsTicker(), watchFetchCompetitions(), watchSetCompetitionLevel()])
}
