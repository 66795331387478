import { Suspense, lazy } from "react"
import { Navigate, Route, Routes } from "react-router"
import { useSelector } from "react-redux"
import BaseLayout from "@/components/BaseLayout"
import PageLoading from "@/components/PageLoading"
import "antd/dist/reset.css"

// Lazy load page components for code splitting
const Bots = lazy(() => import("@/pages/bots.jsx"))
const Backtesting = lazy(() => import("@/pages/backtesting.jsx"))
const BetExplorer = lazy(() => import("@/pages/betexplorer.jsx"))
const GameCenter = lazy(() => import("@/pages/gamecenter.jsx"))
const Users = lazy(() => import("@/pages/users.jsx"))
const Balances = lazy(() => import("@/pages/balances.jsx"))
const TradingPerformance = lazy(() => import("@/pages/trading-performance.jsx"))

const App = () => {
  const authorities = useSelector((state) => state.user.profile.authorities || [])

  return (
    <BaseLayout>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route
            path="/"
            element={
              authorities.includes("ROLE_GAME_CENTER") ? (
                <Navigate to="/game-center" replace />
              ) : (
                <Navigate to="/bots/overview" replace />
              )
            }
          />
          <Route path="/bots/*" element={<Bots />} />
          <Route path="/backtesting/*" element={<Backtesting />} />
          <Route path="/data-sources/*" element={<BetExplorer />} />
          <Route path="/game-center/*" element={<GameCenter />} />
          <Route path="/users/*" element={<Users />} />
          <Route path="/balances/:accountName" element={<Balances />} />
          <Route path="/trading-performance/:accountName" element={<TradingPerformance />} />
        </Routes>
      </Suspense>
    </BaseLayout>
  )
}

export default App
