import { useEffect } from "react"
import { startBackgroundRefresh, stopBackgroundRefresh } from "../../actions/balances"
import { useDispatch, useSelector } from "react-redux"
import { AutoTrackingTrendingMoney, TrendingMoney } from "../TrendingMoney"
import Discretion from "../Discretion"
import "../../less/Balances.less"
import { Link } from "react-router"
import { LineChartOutlined } from "@ant-design/icons"

const GameCenterBalances = () => {
  const dispatch = useDispatch()
  const balances = useSelector((state) => state.balances.balances)
  const inRunningFixtures = useSelector((state) => state.gamecenter.inRunningFixtures)
  const authorities = useSelector((state) => state.user.profile.authorities || [])

  useEffect(() => {
    dispatch(startBackgroundRefresh())

    return () => {
      dispatch(stopBackgroundRefresh())
    }
  }, [dispatch])

  const projectedAccountProfitLoss = inRunningFixtures ? inRunningFixtures.projectedAccountProfitLoss : {}
  const expectedAccountValue = inRunningFixtures ? inRunningFixtures.expectedAccountValue : {}

  return (
    <div className="balances">
      {Object.entries(balances || {}).map(([account, balance]) => {
        const projected = projectedAccountProfitLoss ? projectedAccountProfitLoss[account] || 0 : 0
        const expected = expectedAccountValue ? expectedAccountValue[account] || 0 : 0

        return (
          <div className="accountBalance" key={account}>
            <div className="account">
              {balance.hasDetailedBalances ? (
                <Link to={`/balances/${account}`}>
                  <Discretion>{account}</Discretion>
                </Link>
              ) : (
                <Discretion>{account}</Discretion>
              )}{" "}
              {authorities.includes("ROLE_TRADING_PERFORMANCE") && (
                <Link to={`/trading-performance/${account}`}>
                  <LineChartOutlined />
                </Link>
              )}
            </div>

            <TrendingMoney value={balance.balance} ccy={balance.ccy} trend={balance.balanceTrend} className="balance" />

            {/*<TrendingMoney
              value={balance.credit}
              ccy={balance.ccy}
              trend={balance.balanceTrend}
              className='credit'
            >credit </TrendingMoney>*/}

            <TrendingMoney
              value={Math.abs(balance.openStake)}
              ccy={balance.ccy}
              trend={balance.openStakeTrend}
              className="openStake"
            >
              open{" "}
            </TrendingMoney>
            {projected !== 0 && (
              <>
                <AutoTrackingTrendingMoney
                  value={projected}
                  ccy={balance.ccy}
                  className="projectedDelta"
                  animationDuration={5000}
                  tolerance={50}
                  showSign
                  colored
                >
                  proj{" "}
                </AutoTrackingTrendingMoney>
              </>
            )}
            {expected !== 0 && (
              <>
                <AutoTrackingTrendingMoney
                  value={expected}
                  ccy={balance.ccy}
                  className="projectedDelta"
                  animationDuration={5000}
                  tolerance={50}
                  showSign
                  colored
                >
                  ev{" "}
                </AutoTrackingTrendingMoney>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default GameCenterBalances
