import { Layout } from "antd"
import { Link, Route, Routes } from "react-router"
import TopMenu from "../components/TopMenu"
import "../less/BaseLayout.less"
import logo from "../resources/img/investant.svg"
import UserMenu from "./UserMenu"
import Balances from "./Bot/Balances"
import GameCenterBalances from "./GameCenter/GameCenterBalances"

const { Header, Content, Footer } = Layout

const BaseLayout = ({ children }) => {
  return (
    <Layout className="base-layout" style={{ minHeight: "100vh", minWidth: "1280px" }}>
      <Header style={{ height: "75px", paddingTop: "5px" }}>
        <Link to="/">
          <img src={logo} alt="Investant" className="inv-logo" />
        </Link>
        <UserMenu />
        <Routes>
          <Route path="/game-center" element={<GameCenterBalances />} />
          <Route path="*" element={<Balances />} />
        </Routes>
        <TopMenu />
      </Header>
      <Content style={{ display: "flex" }}>{children}</Content>
    </Layout>
  )
}

export default BaseLayout
