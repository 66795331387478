import { combineReducers } from "redux"
import { Type } from "../actions/broker"

function statusLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_STATUS:
      return true
    case Type.FETCH_STATUS_SUCCEEDED:
    case Type.FETCH_STATUS_FAILED:
      return false
    default:
      return state
  }
}

function statusError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_STATUS_FAILED:
      return action.error
    case Type.FETCH_STATUS:
      return null
    default:
      return state
  }
}

function status(state = [], action) {
  switch (action.type) {
    case Type.FETCH_STATUS_SUCCEEDED:
      return action.status
    case Type.FETCH_STATUS_FAILED:
      return []
    default:
      return state
  }
}

function accountNameBackgroundRefresh(state = null, action) {
  switch (action.type) {
    case Type.START_STATUS_BACKGROUND_REFRESH:
      return action.accountName
    case Type.STOP_STATUS_BACKGROUND_REFRESH:
      return null
    default:
      return state
  }
}

const reducer = combineReducers({
  statusLoading,
  statusError,
  status,
  accountNameBackgroundRefresh,
})

export default reducer
