export const Type = {
  FETCH_COMPETITIONS: "competitions/FETCH_COMPETITIONS",
  FETCH_COMPETITIONS_SUCCEEDED: "competitions/FETCH_COMPETITIONS_SUCCEEDED",
  FETCH_COMPETITIONS_FAILED: "competitions/FETCH_COMPETITIONS_FAILED",
  TABLE_CHANGED: "competitions/TABLE_CHANGED",
}

export function fetchCompetitions() {
  return { type: Type.FETCH_COMPETITIONS }
}

export function fetchCompetitionsSucceeded(page) {
  return { type: Type.FETCH_COMPETITIONS_SUCCEEDED, page }
}

export function fetchCompetitionsFailed(error) {
  return { type: Type.FETCH_COMPETITIONS_FAILED, error }
}

export function tableChanged(pagination, filter, sorter) {
  return { type: Type.TABLE_CHANGED, pagination, filter, sorter }
}
