import { all, call, put, takeLatest } from "redux-saga/effects"
import { Type, fetchModelSucceeded, fetchModelFailed } from "../actions/model"
import { get } from "../lib/fetch"
import Highcharts from "highcharts"

function* watchFetchModel() {
  return yield takeLatest(Type.FETCH_MODEL, fetchModel)
}

function* fetchModel(action) {
  const { values } = action
  try {
    let url = `/api/trading/model/${values.fixtureId}`

    const query = Object.entries(values || {}).flatMap(([key, value]) => {
      switch (typeof value) {
        case "boolean":
        case "number":
        case "string":
          return [`${key}=${value}`]
        case "object":
          if (Highcharts.isArray(value)) {
            return value.map((v) => `${key}=${v}`)
          } else {
            return []
          }
        case "undefined":
          return []
        default:
          console.warn(`Unhandled type ${typeof value} for key ${key} and value ${value}`)
          return []
      }
    })

    if (query.length > 0) {
      url += `?${query.join("&")}`
    }

    const model = yield call(get, url)

    // Add the timestamp so we can decide if we need to recalculate some stuff in the frontend
    yield put(fetchModelSucceeded({ ...model, timestamp: Date.now() }))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchModelFailed(e))
  }
}

// ---

export default function* betExplorerSaga() {
  yield all([watchFetchModel()])
}
