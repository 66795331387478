import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

export function convertFilter(filter) {
  // Create a copy of the filter to avoid mutating the original
  let result = { ...filter }

  // Process edge values
  if ("latestEdge/min" in result) {
    result["latestEdge/min"] =
      result["latestEdge/min"] === 0 ? 0 : result["latestEdge/min"] ? result["latestEdge/min"] / 100 : null
  }
  if ("latestEdge/max" in result) {
    result["latestEdge/max"] =
      result["latestEdge/max"] === 0 ? 0 : result["latestEdge/max"] ? result["latestEdge/max"] / 100 : null
  }
  if ("gotEdge/min" in result) {
    result["gotEdge/min"] = result["gotEdge/min"] === 0 ? 0 : result["gotEdge/min"] ? result["gotEdge/min"] / 100 : null
  }
  if ("gotEdge/max" in result) {
    result["gotEdge/max"] = result["gotEdge/max"] === 0 ? 0 : result["gotEdge/max"] ? result["gotEdge/max"] / 100 : null
  }

  // Process flags
  if ("flags" in result) {
    result["flags"] = result["flags"] ? [result["flags"]] : []
  }

  // Process timestamps
  if ("placementTimestamp" in result) {
    const value = result["placementTimestamp"]
    if (!value || !Array.isArray(value) || value.length < 2) {
      result["placementTimestamp"] = null
    } else {
      result["placementTimestamp"] = {
        min: dayjs.utc(value[0]).startOf("day").valueOf(),
        max: dayjs.utc(value[1]).endOf("day").valueOf(),
      }
    }
  }

  if ("fixtureStartTimestamp" in result) {
    const value = result["fixtureStartTimestamp"]
    if (!value || !Array.isArray(value) || value.length < 2) {
      result["fixtureStartTimestamp"] = null
    } else {
      result["fixtureStartTimestamp"] = {
        min: dayjs.utc(value[0]).startOf("day").valueOf(),
        max: dayjs.utc(value[1]).endOf("day").valueOf(),
      }
    }
  }

  // Process competition flags
  if ("greenlightedCompetitions" in result) {
    const value = result["greenlightedCompetitions"]
    if (!value || !Array.isArray(value) || value.length === 0 || value.length === 2) {
      result["greenlightedCompetitions"] = null
    } else {
      result["greenlightedCompetitions"] = value.includes("greenlighted")
    }
  }

  if ("inRunning" in result) {
    const value = result["inRunning"]
    if (!value || !Array.isArray(value) || value.length === 0 || value.length === 2) {
      result["inRunning"] = null
    } else {
      result["inRunning"] = value.includes("live")
    }
  }

  if ("wasCancelled" in result) {
    const value = result["wasCancelled"]
    if (!value || !Array.isArray(value) || value.length === 0 || value.length === 2) {
      result["wasCancelled"] = null
    } else {
      result["wasCancelled"] = value.includes("cancelled")
    }
  }

  // Convert minutes to seconds
  if ("secondsToFixtureStart/min" in result) {
    result["secondsToFixtureStart/min"] =
      result["secondsToFixtureStart/min"] || result["secondsToFixtureStart/min"] === 0
        ? result["secondsToFixtureStart/min"] * 60
        : null
  }

  if ("secondsToFixtureStart/max" in result) {
    result["secondsToFixtureStart/max"] =
      result["secondsToFixtureStart/max"] || result["secondsToFixtureStart/max"] === 0
        ? result["secondsToFixtureStart/max"] * 60
        : null
  }

  // Convert keys from slash to dot notation
  const convertedResult = {}
  for (const [key, value] of Object.entries(result)) {
    convertedResult[key.replace("/", ".")] = value
  }

  // Deepen the structure
  return deepen(convertedResult)
}

export function convertBacktestingFilter(filter) {
  // Create a copy of the filter to avoid mutating the original
  let result = { ...filter }

  // Process edge values
  if ("wantLatestEdge/min" in result) {
    result["wantLatestEdge/min"] =
      result["wantLatestEdge/min"] === 0 ? 0 : result["wantLatestEdge/min"] ? result["wantLatestEdge/min"] / 100 : null
  }
  if ("wantLatestEdge/max" in result) {
    result["wantLatestEdge/max"] =
      result["wantLatestEdge/max"] === 0 ? 0 : result["wantLatestEdge/max"] ? result["wantLatestEdge/max"] / 100 : null
  }
  if ("bestLatestEdge/min" in result) {
    result["bestLatestEdge/min"] =
      result["bestLatestEdge/min"] === 0 ? 0 : result["bestLatestEdge/min"] ? result["bestLatestEdge/min"] / 100 : null
  }
  if ("bestLatestEdge/max" in result) {
    result["bestLatestEdge/max"] =
      result["bestLatestEdge/max"] === 0 ? 0 : result["bestLatestEdge/max"] ? result["bestLatestEdge/max"] / 100 : null
  }

  // Process timestamps
  if ("placementTimestamp" in result) {
    const value = result["placementTimestamp"]
    if (!value || !Array.isArray(value) || value.length < 2) {
      result["placementTimestamp"] = null
    } else {
      result["placementTimestamp"] = {
        min: dayjs.utc(value[0]).startOf("day").valueOf(),
        max: dayjs.utc(value[1]).endOf("day").valueOf(),
      }
    }
  }

  if ("fixtureStartTimestamp" in result) {
    const value = result["fixtureStartTimestamp"]
    if (!value || !Array.isArray(value) || value.length < 2) {
      result["fixtureStartTimestamp"] = null
    } else {
      result["fixtureStartTimestamp"] = {
        min: dayjs.utc(value[0]).startOf("day").valueOf(),
        max: dayjs.utc(value[1]).endOf("day").valueOf(),
      }
    }
  }

  // Process competition flags
  if ("greenlightedCompetitions" in result) {
    const value = result["greenlightedCompetitions"]
    if (!value || !Array.isArray(value) || value.length === 0 || value.length === 2) {
      result["greenlightedCompetitions"] = null
    } else {
      result["greenlightedCompetitions"] = value.includes("greenlighted")
    }
  }

  if ("inRunning" in result) {
    const value = result["inRunning"]
    if (!value || !Array.isArray(value) || value.length === 0 || value.length === 2) {
      result["inRunning"] = null
    } else {
      result["inRunning"] = value.includes("live")
    }
  }

  if ("wasCancelled" in result) {
    const value = result["wasCancelled"]
    if (!value || !Array.isArray(value) || value.length === 0 || value.length === 2) {
      result["wasCancelled"] = null
    } else {
      result["wasCancelled"] = value.includes("cancelled")
    }
  }

  // Convert minutes to seconds
  if ("secondsToFixtureStart/min" in result) {
    result["secondsToFixtureStart/min"] =
      result["secondsToFixtureStart/min"] || result["secondsToFixtureStart/min"] === 0
        ? result["secondsToFixtureStart/min"] * 60
        : null
  }

  if ("secondsToFixtureStart/max" in result) {
    result["secondsToFixtureStart/max"] =
      result["secondsToFixtureStart/max"] || result["secondsToFixtureStart/max"] === 0
        ? result["secondsToFixtureStart/max"] * 60
        : null
  }

  // Convert keys from slash to dot notation
  const convertedResult = {}
  for (const [key, value] of Object.entries(result)) {
    convertedResult[key.replace("/", ".")] = value
  }

  // Deepen the structure
  return deepen(convertedResult)
}

// Things you have to do...
function deepen(o) {
  const oo = {}
  let t, parts, part
  for (let k in o) {
    t = oo
    parts = k.split(".")
    const key = parts.pop()
    while (parts.length) {
      part = parts.shift()
      t = t[part] = t[part] || {}
    }
    t[key] = o[k]
  }
  return oo
}
