export const Type = {
  SET_SPORTS_TICKERS_DATE: "runningball/SET_SPORTS_TICKERS_DATE",
  FETCH_SPORTS_TICKERS: "runningball/FETCH_SPORTS_TICKERS",
  FETCH_SPORTS_TICKERS_SUCCEEDED: "runningball/FETCH_SPORTS_TICKERS_SUCCEEDED",
  FETCH_SPORTS_TICKERS_FAILED: "runningball/FETCH_SPORTS_TICKERS_FAILED",
  FETCH_SPORTS_TICKER: "runningball/FETCH_SPORTS_TICKER",
  FETCH_SPORTS_TICKER_SUCCEEDED: "runningball/FETCH_SPORTS_TICKER_SUCCEEDED",
  FETCH_SPORTS_TICKER_FAILED: "runningball/FETCH_SPORTS_TICKER_FAILED",
  FETCH_COMPETITIONS: "runningball/FETCH_COMPETITIONS",
  FETCH_COMPETITIONS_SUCCEEDED: "runningball/FETCH_COMPETITIONS_SUCCEEDED",
  FETCH_COMPETITIONS_FAILED: "runningball/FETCH_COMPETITIONS_FAILED",
  COMPETITIONS_COUNTRY_SELECTED: "runningball/COMPETITIONS_COUNTRY_SELECTED",
  SET_COMPETITION_LEVEL: "runningball/SET_COMPETITION_LEVEL",
  SET_COMPETITION_LEVEL_SUCCEEDED: "runningball/SET_COMPETITION_LEVEL_SUCCEEDED",
  SET_COMPETITION_LEVEL_FAILED: "runningball/SET_COMPETITION_LEVEL_FAILED",
}

export function setSportsTickersDate(date) {
  return { type: Type.SET_SPORTS_TICKERS_DATE, date }
}

export function fetchSportsTickers() {
  return { type: Type.FETCH_SPORTS_TICKERS }
}

export function fetchSportsTickersSucceeded(tickers) {
  return { type: Type.FETCH_SPORTS_TICKERS_SUCCEEDED, tickers }
}

export function fetchSportsTickersFailed(error) {
  return { type: Type.FETCH_SPORTS_TICKERS_FAILED, error }
}

export function fetchSportsTicker(id) {
  return { type: Type.FETCH_SPORTS_TICKER, id }
}

export function fetchSportsTickerSucceeded(ticker) {
  return { type: Type.FETCH_SPORTS_TICKER_SUCCEEDED, ticker }
}

export function fetchSportsTickerFailed(error) {
  return { type: Type.FETCH_SPORTS_TICKER_FAILED, error }
}

export function fetchCompetitions() {
  return { type: Type.FETCH_COMPETITIONS }
}

export function fetchCompetitionsSucceeded(competitions) {
  return { type: Type.FETCH_COMPETITIONS_SUCCEEDED, competitions }
}

export function fetchCompetitionsFailed(error) {
  return { type: Type.FETCH_COMPETITIONS_FAILED, error }
}

export function competitionsCountrySelected(country) {
  return { type: Type.COMPETITIONS_COUNTRY_SELECTED, country }
}

export function setCompetitionLevel(countryKey, id, level) {
  return { type: Type.SET_COMPETITION_LEVEL, countryKey, id, level }
}

export function setCompetitionLevelSucceeded(countryKey, id, level) {
  return { type: Type.SET_COMPETITION_LEVEL_SUCCEEDED, countryKey, id, level }
}

export function setCompetitionLevelFailed(countryKey, id, level) {
  return { type: Type.SET_COMPETITION_LEVEL_FAILED, countryKey, id, level }
}
