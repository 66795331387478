import classNames from "classnames"
import "../less/Balances.less"
import { Money } from "../lib/intlHelpers"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import { useEffect, useState, useRef } from "react"

export const AutoTrackingTrendingMoney = ({ value, tolerance = 10, animationDuration = 15000, ...props }) => {
  const [trend, setTrend] = useState(undefined)
  const animateTimeoutRef = useRef(null)
  const prevValueRef = useRef(value)

  useEffect(() => {
    // Check if value has changed significantly
    if (value - prevValueRef.current > tolerance) {
      // Protect against animations from exchange rate changes
      clearTimeout(animateTimeoutRef.current)
      setTrend("up")
      animateTimeoutRef.current = setTimeout(() => setTrend(undefined), animationDuration)
    } else if (value - prevValueRef.current < -tolerance) {
      clearTimeout(animateTimeoutRef.current)
      setTrend("down")
      animateTimeoutRef.current = setTimeout(() => setTrend(undefined), animationDuration)
    }

    // Update previous value reference
    prevValueRef.current = value
  }, [value, tolerance, animationDuration])

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      clearTimeout(animateTimeoutRef.current)
    }
  }, [])

  return <TrendingMoney animate {...props} value={value} trend={trend} />
}

export const TrendingMoney = ({ value, ccy, trend, className, children, animate, colored, showSign }) => (
  <span className={className}>
    {children}
    <span
      className={classNames({
        up: trend === "up",
        down: trend === "down",
      })}
    >
      <Money showSign={showSign} colored={colored} value={value} ccy={ccy} />{" "}
      {trend === "up" && (
        <ArrowUpOutlined
          style={{ opacity: trend ? 1 : 0 }}
          className={classNames({
            "animated infinite": trend,
            "fadeInUp slow": trend === "up",
          })}
        />
      )}
      {trend === "down" && (
        <ArrowDownOutlined
          style={{ opacity: trend ? 1 : 0 }}
          className={classNames({
            "animated infinite": trend,
            "fadeInDown slow": trend === "down",
          })}
        />
      )}
    </span>
  </span>
)
