import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchBalancesSucceeded,
  fetchBalancesFailed,
  fetchBalances as fetchBalances_1,
  fetchUserBalancesSucceeded,
  fetchUserBalancesFailed,
  fetchUserBalances as fetchUserBalances_1,
  deleteBalanceSucceeded,
  deleteBalanceFailed,
} from "../actions/balances"
import { get, httpDelete } from "../lib/fetch"

function* watchFetchBalances() {
  return yield takeLatest(Type.FETCH_BALANCES, fetchBalances)
}

function* fetchBalances() {
  try {
    const balances = yield call(get, `/api/trading/balances`)
    yield put(fetchBalancesSucceeded(balances))
  } catch (e) {
    yield put(fetchBalancesFailed(e))
  }
}

// ---

function* backgroundRefresh() {
  while (true) {
    const refresh = yield select((state) => state.balances.backgroundRefresh)
    if (!refresh) {
      break
    }
    yield put(fetchBalances_1())
    yield delay(5000)
  }
}

function* watchStartBackgroundRefresh() {
  yield takeLatest(Type.START_BACKGROUND_REFRESH, backgroundRefresh)
}

// ---

function* watchFetchUserBalances() {
  return yield takeLatest(Type.FETCH_USER_BALANCES, fetchUserBalances)
}

function* fetchUserBalances({ accountName }) {
  try {
    const userBalances = yield call(get, `/api/trading/balances/${accountName}`)
    yield put(fetchUserBalancesSucceeded(accountName, userBalances))
  } catch (e) {
    yield put(fetchUserBalancesFailed(e))
  }
}

// ---

function* userBackgroundRefresh() {
  while (true) {
    const accountName = yield select((state) => state.balances.userBackgroundRefresh)
    if (accountName === null) {
      break
    }
    yield put(fetchUserBalances_1(accountName))
    yield delay(5000)
  }
}

function* watchStartUserBackgroundRefresh() {
  yield takeLatest(Type.START_USER_BACKGROUND_REFRESH, userBackgroundRefresh)
}

// ---

function* watchDeleteBalance() {
  return yield takeEvery(Type.DELETE_BALANCE, deleteBalance)
}

function* deleteBalance({ accountName, bookie, username }) {
  try {
    yield call(httpDelete, `/api/trading/balances/${accountName}?bookie=${bookie}&username=${username}`)
    yield put(deleteBalanceSucceeded(accountName, bookie, username))
  } catch (e) {
    yield put(deleteBalanceFailed(accountName, bookie, username, e))
  }
}

// ---

export default function* balancesSaga() {
  yield all([
    watchFetchBalances(),
    watchStartBackgroundRefresh(),
    watchFetchUserBalances(),
    watchStartUserBackgroundRefresh(),
    watchDeleteBalance(),
  ])
}
