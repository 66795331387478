export const Type = {
  FETCH_DASHBOARD_STATS: "dashboard/FETCH_DASHBOARD_STATS",
  FETCH_DASHBOARD_STATS_SUCCEEDED: "dashboard/FETCH_DASHBOARD_STATS_SUCCEEDED",
  FETCH_DASHBOARD_STATS_FAILED: "dashboard/FETCH_DASHBOARD_STATS_FAILED",
  FETCH_DASHBOARD_GRAPH: "dashboard/FETCH_DASHBOARD_GRAPH",
  FETCH_DASHBOARD_GRAPH_SUCCEEDED: "dashboard/FETCH_DASHBOARD_GRAPH_SUCCEEDED",
  FETCH_DASHBOARD_GRAPH_FAILED: "dashboard/FETCH_DASHBOARD_GRAPH_FAILED",
  TOGGLE_DASHBOARD_GRAPH_BY_TIME: "dashboard/TOGGLE_DASHBOARD_GRAPH_BY_TIME",
}

export function fetchDashboardStats(fixedFilter) {
  return { type: Type.FETCH_DASHBOARD_STATS, fixedFilter }
}

export function fetchDashboardStatsSucceeded(page) {
  return { type: Type.FETCH_DASHBOARD_STATS_SUCCEEDED, page }
}

export function fetchDashboardStatsFailed(error) {
  return { type: Type.FETCH_DASHBOARD_STATS_FAILED, error }
}

export function fetchDashboardGraph(fixedFilter, useBacktestingFilter) {
  return {
    type: Type.FETCH_DASHBOARD_GRAPH,
    fixedFilter,
    useBacktestingFilter,
  }
}

export function fetchDashboardGraphSucceeded(page) {
  return { type: Type.FETCH_DASHBOARD_GRAPH_SUCCEEDED, page }
}

export function fetchDashboardGraphFailed(error) {
  return { type: Type.FETCH_DASHBOARD_GRAPH_FAILED, error }
}

export function toggleDashboardGraphByTime() {
  return { type: Type.TOGGLE_DASHBOARD_GRAPH_BY_TIME }
}
