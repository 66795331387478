import { combineReducers } from "redux"
import { Type } from "../actions/overview"
import { Type as BotsType } from "../actions/bots"

function statsLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_OVERVIEW_STATS:
      return true
    case Type.FETCH_OVERVIEW_STATS_FAILED:
    case Type.FETCH_OVERVIEW_STATS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function graphLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_OVERVIEW_GRAPH:
      return true
    case Type.FETCH_OVERVIEW_GRAPH_FAILED:
    case Type.FETCH_OVERVIEW_GRAPH_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_OVERVIEW_GRAPH_FAILED:
    case Type.FETCH_OVERVIEW_STATS_FAILED:
      return action.error
    case Type.FETCH_OVERVIEW_GRAPH:
    case Type.FETCH_OVERVIEW_STATS:
      return null
    default:
      return state
  }
}

function overviewStats(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_OVERVIEW_STATS_SUCCEEDED:
      return action.page
    default:
      return state
  }
}

function overviewGraph(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_OVERVIEW_GRAPH_SUCCEEDED:
      return action.page
    default:
      return state
  }
}

function selectedBots(state = {}, action) {
  switch (action.type) {
    case Type.SET_SELECTED_BOTS:
      return {
        ...state,
        [action.accountId]: action.selectedBots,
      }
    case BotsType.FETCH_BOTS_SUCCEEDED:
      const result = { ...state }

      // Process each account in the action.page
      Object.entries(action.page).forEach(([accountId, bots]) => {
        // Filter active bots and extract their principalIds
        const activeBotIds = bots
          .filter((bot) => bot.activeInstances && bot.activeInstances.length > 0)
          .map((bot) => bot.principalId)

        // Store the set of active bot IDs for this account
        result[accountId] = activeBotIds
      })

      return result
    default:
      return state
  }
}

const reducer = combineReducers({
  statsLoading,
  graphLoading,
  error,
  overviewStats,
  overviewGraph,
  selectedBots,
})

export default reducer
