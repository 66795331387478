import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import rootReducer from "../reducers"
import rootSaga from "../sagas"

const sagaMiddleware = createSagaMiddleware()

// Create the store instance immediately
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
  devTools: import.meta.env.MODE !== "production",
})

if (import.meta.hot) {
  import.meta.hot.accept("../reducers/index", (newModule) => {
    if (newModule) {
      store.replaceReducer(newModule.default)
    }
  })
}

sagaMiddleware.run(rootSaga)

// Export the store instance
export const { dispatch, getState } = store
export { store }

// Keep createAppStore for testing purposes
function createAppStore(initialState = {}) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
    devTools: import.meta.env.MODE !== "production",
  })

  if (import.meta.hot) {
    import.meta.hot.accept("../reducers/index", (newModule) => {
      if (newModule) {
        store.replaceReducer(newModule.default)
      }
    })
  }

  sagaMiddleware.run(rootSaga)
  return store
}

export default createAppStore
