export const Type = {
  FETCH_BOOKIE_SHORTCUTS: "everymatrix/FETCH_BOOKIE_SHORTCUTS",
  FETCH_BOOKIE_SHORTCUTS_SUCCEEDED: "everymatrix/FETCH_BOOKIE_SHORTCUTS_SUCCEEDED",
  FETCH_BOOKIE_SHORTCUTS_FAILED: "everymatrix/FETCH_BOOKIE_SHORTCUTS_FAILED",
  ADD_BOOKIE_SHORTCUT: "everymatrix/ADD_BOOKIE_SHORTCUT",
  ADD_BOOKIE_SHORTCUT_SUCCEEDED: "everymatrix/ADD_BOOKIE_SHORTCUT_SUCCEEDED",
  ADD_BOOKIE_SHORTCUT_FAILED: "everymatrix/ADD_BOOKIE_SHORTCUT_FAILED",
  DELETE_BOOKIE_SHORTCUT: "everymatrix/DELETE_BOOKIE_SHORTCUT",
  DELETE_BOOKIE_SHORTCUT_SUCCEEDED: "everymatrix/DELETE_BOOKIE_SHORTCUT_SUCCEEDED",
  DELETE_BOOKIE_SHORTCUT_FAILED: "everymatrix/DELETE_BOOKIE_SHORTCUT_FAILED",
}

export function fetchBookieShortcuts() {
  return { type: Type.FETCH_BOOKIE_SHORTCUTS }
}

export function fetchBookieShortcutsSucceeded(bookieShortcuts) {
  return { type: Type.FETCH_BOOKIE_SHORTCUTS_SUCCEEDED, bookieShortcuts }
}

export function fetchBookieShortcutsFailed(error) {
  return { type: Type.FETCH_BOOKIE_SHORTCUTS_FAILED, error }
}

export function addBookieShortcut(bookieId, shortcut) {
  return { type: Type.ADD_BOOKIE_SHORTCUT, bookieId, shortcut }
}

export function addBookieShortcutSucceeded(bookieId, shortcut) {
  return { type: Type.ADD_BOOKIE_SHORTCUT_SUCCEEDED, bookieId, shortcut }
}

export function addBookieShortcutFailed(bookieId, shortcut, error) {
  return { type: Type.ADD_BOOKIE_SHORTCUT_FAILED, bookieId, shortcut, error }
}

export function deleteBookieShortcut(bookieId) {
  return { type: Type.DELETE_BOOKIE_SHORTCUT, bookieId }
}

export function deleteBookieShortcutSucceeded(bookieId) {
  return { type: Type.DELETE_BOOKIE_SHORTCUT_SUCCEEDED, bookieId }
}

export function deleteBookieShortcutFailed(bookieId, error) {
  return { type: Type.DELETE_BOOKIE_SHORTCUT_FAILED, bookieId, error }
}
