import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchOrdersSucceeded,
  fetchOrdersFailed,
  fetchOrderPriceGraphSucceeded,
  fetchOrderPriceGraphFailed,
  fetchModelPriceGraphSucceeded,
  fetchModelPriceGraphFailed,
  fetchFixtureGraphSucceeded,
  fetchFixtureGraphFailed,
  fetchBacktestsSucceeded,
  fetchBacktestsFailed,
  fetchBacktestProgressSucceeded,
  fetchBacktestProgressFailed,
  fetchDashboardStatsSucceeded,
  fetchDashboardStatsFailed,
  fetchDashboardGraphSucceeded,
  fetchDashboardGraphFailed,
  fetchAnalyticsSucceeded,
  fetchAnalyticsFailed,
  fetchBotMatchSucceeded,
  fetchBotMatchFailed,
  changeNoteSucceeded,
  changeNoteFailed,
  browseGameCenterSucceeded,
  browseGameCenterFailed,
} from "../actions/backtesting"
import { get, post, postRaw } from "../lib/fetch"
import { convertBacktestingFilter } from "./ordersFilter"
import { message } from "antd"

function* watchFetchBacktestingOrders() {
  return yield takeLatest(Type.FETCH_ORDERS, fetchBacktestingOrders)
}

function* fetchBacktestingOrders(action) {
  try {
    const table = yield select((state) => state.backtesting.table)
    let filter = yield select((state) => convertBacktestingFilter(state.backtesting.filterFields))

    filter = {
      ...filter,
      ...action.fixedFilter,
    }

    const current = table.pagination?.current || 1
    const pageSize = table.pagination?.pageSize || 20

    const page = yield call(post, `/api/trading/backtesting/orders?page=${current}&pageSize=${pageSize}`, filter)
    yield put(fetchOrdersSucceeded(page))
  } catch (e) {
    console.log("Error", e)
    yield put(fetchOrdersFailed(e))
  }
}

// ---

function* watchFetchOrderPriceGraph() {
  return yield takeEvery(Type.FETCH_ORDER_PRICE_GRAPH, fetchOrderPriceGraph)
}

function* fetchOrderPriceGraph(action) {
  const { bookie } = action
  try {
    let url = `/api/trading/backtesting/${encodeURIComponent(action.orderUuid)}/price-graph?bookie=${bookie}`
    let page = yield call(get, url)
    yield put(fetchOrderPriceGraphSucceeded(bookie, page))
  } catch (e) {
    yield put(fetchOrderPriceGraphFailed(bookie, e))
  }
}

// ---

function* watchFetchModelPriceGraph() {
  return yield takeEvery(Type.FETCH_MODEL_PRICE_GRAPH, fetchModelPriceGraph)
}

function* fetchModelPriceGraph(action) {
  try {
    let url = `/api/trading/backtesting/${encodeURIComponent(action.orderUuid)}/model-graph`
    const query = []
    if (typeof action.homeScoreRate === "number") {
      query.push(`homeScoreRate=${action.homeScoreRate}`)
    }
    if (typeof action.awayScoreRate === "number") {
      query.push(`awayScoreRate=${action.awayScoreRate}`)
    }
    if (typeof action.nu === "number") {
      query.push(`nu=${action.nu}`)
    }
    if (typeof action.zeroAdjustment === "number") {
      query.push(`zeroAdjustment=${action.zeroAdjustment}`)
    }
    if (query.length > 0) {
      url += `?${query.join("&")}`
    }
    let values = yield call(get, url)
    yield put(fetchModelPriceGraphSucceeded(values))
  } catch (e) {
    yield put(fetchModelPriceGraphFailed(e))
  }
}

// ---

function* watchFetchFixtureGraph() {
  return yield takeEvery(Type.FETCH_FIXTURE_GRAPH, fetchFixtureGraph)
}

function* fetchFixtureGraph(action) {
  try {
    let page = yield call(get, `/api/trading/backtesting/${encodeURIComponent(action.orderUuid)}/fixture-graph`)
    yield put(fetchFixtureGraphSucceeded(page))
  } catch (e) {
    yield put(fetchFixtureGraphFailed(e))
  }
}

// ---

function* watchFetchBacktests() {
  return yield takeLatest(Type.FETCH_BACKTESTS, fetchBacktests)
}

function* fetchBacktests() {
  try {
    const backtests = yield call(get, `/api/trading/backtesting`)
    yield put(fetchBacktestsSucceeded(backtests))
  } catch (e) {
    yield put(fetchBacktestsFailed(e))
  }
}

// ---

function* watchFetchBacktestProgress() {
  return yield takeLatest(Type.FETCH_BACKTEST_PROGRESS, fetchBacktestProgress)
}

function* fetchBacktestProgress(action) {
  try {
    const progress = yield call(get, `/api/trading/backtesting/progress/${action.principalId}`)
    yield put(fetchBacktestProgressSucceeded(action.principalId, progress))
  } catch (e) {
    yield put(fetchBacktestProgressFailed(action.principalId, e))
  }
}

// ---

function* watchClearNodes() {
  return yield takeLatest(Type.CLEAR_NODES, clearNodes)
}

function* clearNodes(action) {
  try {
    const { principalId } = action
    yield call(post, `/api/trading/backtesting/clear-nodes?principalId=${encodeURIComponent(principalId)}`)
  } catch (e) {
    console.error(e)
  }
}

// ---

function* watchFetchDashboardStats() {
  return yield takeLatest(Type.FETCH_DASHBOARD_STATS, fetchDashboardStats)
}

function* fetchDashboardStats(action) {
  try {
    let filter = yield select((state) => convertBacktestingFilter(state.backtesting.filterFields))
    let customStakingEnabled = yield select((state) => state.backtesting.customStakingEnabled)
    let customStaking = null

    if (customStakingEnabled) {
      customStaking = yield select((state) => state.backtesting.customStaking)
    }

    filter = {
      ...filter,
      ...action.fixedFilter,
    }

    let page = yield call(post, "/api/trading/backtesting/analytics", {
      filter,
      customStaking,
    })

    const sharpeRatios = yield call(post, `/api/trading/backtesting/sharpe-ratio`, filter)
    const settlingStats = yield call(post, `/api/trading/backtesting/settling-stats`, filter)

    page = {
      ...page,
      ...sharpeRatios,
      settlingStats,
    }

    yield put(fetchDashboardStatsSucceeded(page))
  } catch (e) {
    yield put(fetchDashboardStatsFailed(e))
  }
}

// ---

function* watchFetchDashboardGraph() {
  return yield takeEvery(Type.FETCH_DASHBOARD_GRAPH, fetchDashboardGraph)
}

function* fetchDashboardGraph(action) {
  const { principalId } = action
  try {
    let filter = yield select((state) => convertBacktestingFilter(state.backtesting.filterFields))
    let customStakingEnabled = yield select((state) => state.backtesting.customStakingEnabled)
    let customStaking = null

    if (customStakingEnabled) {
      customStaking = yield select((state) => state.backtesting.customStaking)
    }

    filter = {
      ...filter,
      principalIds: [principalId],
    }

    const page = yield call(post, `/api/trading/backtesting/analytics?groupBy=fixtureStartTimestamp(auto)`, {
      filter,
      customStaking,
    })

    yield put(fetchDashboardGraphSucceeded(principalId, page))
  } catch (e) {
    console.error(e)
    yield put(fetchDashboardGraphFailed(principalId, e))
  }
}

// ---

function* watchFetchAnalytics() {
  return yield takeLatest(Type.FETCH_ANALYTICS, fetchAnalytics)
}

function* fetchAnalytics(action) {
  try {
    const groupBy = yield select((state) => state.backtesting.analyticsGroupBy)
    let filter = yield select((state) => convertBacktestingFilter(state.backtesting.filterFields))
    let customStakingEnabled = yield select((state) => state.backtesting.customStakingEnabled)
    let customStaking = null

    if (customStakingEnabled) {
      customStaking = yield select((state) => state.backtesting.customStaking)
    }

    filter = {
      ...filter,
      ...action.fixedFilter,
    }

    const analytics = yield call(
      post,
      "/api/trading/backtesting/analytics" + (groupBy ? `?groupBy=${groupBy[0]}(${groupBy[1] || ""})` : ""),
      {
        filter,
        customStaking,
      },
    )

    yield put(fetchAnalyticsSucceeded(analytics))
  } catch (e) {
    yield put(fetchAnalyticsFailed(e))
  }
}

// ---

function* watchFetchBotMatch() {
  return yield takeLatest(Type.FETCH_BOT_MATCH, fetchBotMatch)
}

function* fetchBotMatch(action) {
  try {
    const { fixtureId, botAccount, botPrincipalId, backtestPrincipalId } = action

    const matches = yield call(
      get,
      `/api/trading/backtesting/match?fixtureId=${fixtureId}&botAccount=${botAccount}&botPrincipalId=${botPrincipalId}&backtestPrincipalId=${backtestPrincipalId}`,
    )

    yield put(fetchBotMatchSucceeded(matches))
  } catch (e) {
    yield put(fetchBotMatchFailed(e))
  }
}

// ---

function* watchChangeNote() {
  return yield takeLatest(Type.CHANGE_NOTE, changeNote)
}

function* changeNote(action) {
  const { principalId, note } = action
  try {
    yield call(post, `/api/trading/backtesting/note`, { principalId, note })

    yield put(changeNoteSucceeded(principalId))
  } catch (e) {
    yield put(changeNoteFailed(principalId, e))
  }
}

// ---

function* watchFetchBrowseGameCenter() {
  return yield takeLatest(Type.BROWSE_GAME_CENTER, fetchBrowseGameCenter)
}

function* fetchBrowseGameCenter(action) {
  try {
    const { principalId, from, to } = action
    const { botAccount, botPrincipalId } = yield select((state) => state.backtesting.browseGameCenterBotPrincipalId)
    const comparePrincipalId = yield select((state) => state.backtesting.browseGameCenterComparePrincipalId)
    const customFilter = yield select((state) => state.backtesting.gameCenterCustomFilter)
    const response = yield call(
      postRaw,
      `/api/game-center/backtesting/browse/${principalId}?from=${from}&to=${to}` +
        (botPrincipalId ? `&botPrincipalId=${botPrincipalId}&botAccount=${botAccount}` : "") +
        (comparePrincipalId ? `&comparePrincipalId=${comparePrincipalId}` : ""),
      customFilter,
    )
    yield put(browseGameCenterSucceeded(response))
  } catch (e) {
    message.error(e.message, 5)
    yield put(browseGameCenterFailed(e))
  }
}

// ---

export default function* backtestingSaga() {
  yield all([
    watchFetchBacktests(),
    watchFetchBacktestingOrders(),
    watchFetchBacktestProgress(),
    watchFetchOrderPriceGraph(),
    watchFetchFixtureGraph(),
    watchFetchDashboardStats(),
    watchFetchDashboardGraph(),
    watchFetchModelPriceGraph(),
    watchFetchAnalytics(),
    watchFetchBotMatch(),
    watchClearNodes(),
    watchChangeNote(),
    watchFetchBrowseGameCenter(),
  ])
}
