export const Type = {
  FETCH_ANALYTICS: "analytics/FETCH_ANALYTICS",
  FETCH_ANALYTICS_SUCCEEDED: "analytics/FETCH_ANALYTICS_SUCCEEDED",
  FETCH_ANALYTICS_FAILED: "analytics/FETCH_ANALYTICS_FAILED",
  SET_GROUP_BY: "analytics/SET_GROUP_BY",
  SET_FLAT_STAKING: "analytics/SET_FLAT_STAKING",
  SET_INCLUDE_COMMISSION: "analytics/SET_INCLUDE_COMMISSION",
  SET_ADDITIONAL_COLUMNS: "analytics/SET_ADDITIONAL_COLUMNS",
}

export function fetchAnalytics(fixedFilter) {
  return { type: Type.FETCH_ANALYTICS, fixedFilter }
}

export function fetchAnalyticsSucceeded(analytics) {
  return { type: Type.FETCH_ANALYTICS_SUCCEEDED, analytics }
}

export function fetchAnalyticsFailed(error) {
  return { type: Type.FETCH_ANALYTICS_FAILED, error }
}

export function setGroupBy(groupBy) {
  return { type: Type.SET_GROUP_BY, groupBy }
}

export function setFlatStaking(flatStaking) {
  return { type: Type.SET_FLAT_STAKING, flatStaking }
}

export function setIncludeCommission(includeCommission) {
  return { type: Type.SET_INCLUDE_COMMISSION, includeCommission }
}

export function setAdditionalColumns(additionalColumns) {
  return { type: Type.SET_ADDITIONAL_COLUMNS, additionalColumns }
}
