export const Type = {
  FETCH_BALANCES: "balances/FETCH_BALANCES",
  FETCH_BALANCES_SUCCEEDED: "balances/FETCH_BALANCES_SUCCEEDED",
  FETCH_BALANCES_FAILED: "balances/FETCH_BALANCES_FAILED",
  START_BACKGROUND_REFRESH: "balances/START_BACKGROUND_REFRESH",
  STOP_BACKGROUND_REFRESH: "balances/STOP_BACKGROUND_REFRESH",
  FETCH_USER_BALANCES: "balances/FETCH_USER_BALANCES",
  FETCH_USER_BALANCES_SUCCEEDED: "balances/FETCH_USER_BALANCES_SUCCEEDED",
  FETCH_USER_BALANCES_FAILED: "balances/FETCH_USER_BALANCES_FAILED",
  START_USER_BACKGROUND_REFRESH: "balances/START_USER_BACKGROUND_REFRESH",
  STOP_USER_BACKGROUND_REFRESH: "balances/STOP_USER_BACKGROUND_REFRESH",
  DELETE_BALANCE: "balances/DELETE_BALANCE",
  DELETE_BALANCE_SUCCEEDED: "balances/DELETE_BALANCE_SUCCEEDED",
  DELETE_BALANCE_FAILED: "balances/DELETE_BALANCE_FAILED",
}

export function fetchBalances() {
  return { type: Type.FETCH_BALANCES }
}

export function fetchBalancesSucceeded(balances) {
  return { type: Type.FETCH_BALANCES_SUCCEEDED, balances }
}

export function fetchBalancesFailed(error) {
  return { type: Type.FETCH_BALANCES_FAILED, error }
}

export function startBackgroundRefresh() {
  return { type: Type.START_BACKGROUND_REFRESH }
}

export function stopBackgroundRefresh() {
  return { type: Type.STOP_BACKGROUND_REFRESH }
}

export function fetchUserBalances(accountName) {
  return { type: Type.FETCH_USER_BALANCES, accountName }
}

export function fetchUserBalancesSucceeded(accountName, userBalances) {
  return {
    type: Type.FETCH_USER_BALANCES_SUCCEEDED,
    accountName,
    userBalances,
  }
}

export function fetchUserBalancesFailed(accountName, error) {
  return { type: Type.FETCH_USER_BALANCES_FAILED, accountName, error }
}

export function startUserBackgroundRefresh(accountName) {
  return { type: Type.START_USER_BACKGROUND_REFRESH, accountName }
}

export function stopUserBackgroundRefresh(accountName) {
  return { type: Type.STOP_USER_BACKGROUND_REFRESH, accountName }
}

export function deleteBalance(accountName, bookie, username) {
  return { type: Type.DELETE_BALANCE, accountName, bookie, username }
}

export function deleteBalanceSucceeded(accountName, bookie, username) {
  return { type: Type.FETCH_BALANCES_SUCCEEDED, accountName, bookie, username }
}

export function deleteBalanceFailed(accountName, bookie, username, error) {
  return {
    type: Type.FETCH_BALANCES_FAILED,
    accountName,
    bookie,
    username,
    error,
  }
}
