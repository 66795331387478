export const Slant = ({ height = "143px", slantHeight = "80px" }) => (
  <div
    style={{
      position: "absolute",
      top: "-3px",
      left: 0,
      background: "#111827",
      width: "100%",
      height,
      overflow: "hidden",
    }}
  >
    {/*<svg style={{position:'absolute', left: 0, bottom: 0, width: '200%', height: slantHeight, fill:'#f0f2f5'}} viewBox="0 0 1 1" preserveAspectRatio="none">*/}
    {/*  <polygon points="1,1 0,0 0,1"/>*/}
    {/*</svg>*/}
  </div>
)
