import { combineReducers } from "redux"
import { Type } from "../actions/user"

function authenticated(state = null, action) {
  switch (action.type) {
    case Type.FETCH_MYSELF_SUCCEEDED:
      return true

    case Type.FETCH_MYSELF_FAILED:
    case Type.SIGN_OUT_SUCCEEDED:
      return false

    default:
      return state
  }
}

function profile(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_MYSELF_SUCCEEDED:
      // Ensure we have authorities as a regular array
      const myself = action.myself || {}
      if (myself.authorities && !Array.isArray(myself.authorities)) {
        myself.authorities = Array.isArray(myself.authorities) ? myself.authorities : []
      }
      return myself

    case Type.SIGN_OUT_SUCCEEDED:
      return {}

    default:
      return state
  }
}

function discretionMode(state = false, action) {
  switch (action.type) {
    case Type.TOGGLE_DISCRETION_MODE:
      return !state
    default:
      return state
  }
}

const reducer = combineReducers({
  authenticated,
  profile,
  discretionMode,
})

export default reducer
