export const Type = {
  FETCH_ORDERS: "orders/FETCH_ORDERS",
  FETCH_ORDERS_SUCCEEDED: "orders/FETCH_ORDERS_SUCCEEDED",
  FETCH_ORDERS_FAILED: "orders/FETCH_ORDERS_FAILED",
  TABLE_CHANGED: "orders/TABLE_CHANGED",
  FILTER_FIELDS_CHANGED: "orders/FILTER_FIELDS_CHANGED",
  ORDER_OPENED: "orders/ORDER_OPENED",
  ORDER_CLOSED: "orders/ORDER_CLOSED",
  FETCH_ORDER_PRICE_GRAPH: "orders/FETCH_ORDER_PRICE_GRAPH",
  FETCH_ORDER_PRICE_GRAPH_SUCCEEDED: "orders/FETCH_ORDER_PRICE_GRAPH_SUCCEEDED",
  FETCH_ORDER_PRICE_GRAPH_FAILED: "orders/FETCH_ORDER_PRICE_GRAPH_FAILED",
  FETCH_MODEL_PRICE_GRAPH: "orders/FETCH_MODEL_PRICE_GRAPH",
  FETCH_MODEL_PRICE_GRAPH_SUCCEEDED: "orders/FETCH_MODEL_PRICE_GRAPH_SUCCEEDED",
  FETCH_MODEL_PRICE_GRAPH_FAILED: "orders/FETCH_MODEL_PRICE_GRAPH_FAILED",
  FETCH_FIXTURE_GRAPH: "orders/FETCH_FIXTURE_GRAPH",
  FETCH_FIXTURE_GRAPH_SUCCEEDED: "orders/FETCH_FIXTURE_GRAPH_SUCCEEDED",
  FETCH_FIXTURE_GRAPH_FAILED: "orders/FETCH_FIXTURE_GRAPH_FAILED",
  FETCH_RUNNINGBALL_GRAPH: "orders/FETCH_RUNNINGBALL_GRAPH",
  FETCH_RUNNINGBALL_GRAPH_SUCCEEDED: "orders/FETCH_RUNNINGBALL_GRAPH_SUCCEEDED",
  FETCH_RUNNINGBALL_GRAPH_FAILED: "orders/FETCH_RUNNINGBALL_GRAPH_FAILED",
  START_BACKGROUND_REFRESH: "orders/START_BACKGROUND_REFRESH",
  STOP_BACKGROUND_REFRESH: "orders/STOP_BACKGROUND_REFRESH",
  FETCH_VALUES: "orders/FETCH_VALUES",
  FETCH_VALUES_SUCCEEDED: "orders/FETCH_VALUES_SUCCEEDED",
  FETCH_VALUES_FAILED: "orders/FETCH_VALUES_FAILED",
  FETCH_ORDER_CHILDREN: "orders/FETCH_ORDER_CHILDREN",
  FETCH_ORDER_CHILDREN_SUCCEEDED: "orders/FETCH_ORDER_CHILDREN_SUCCEEDED",
  FETCH_ORDER_CHILDREN_FAILED: "orders/FETCH_ORDER_CHILDREN_FAILED",
  TOGGLE_INCLUDE_BETSLIPS: "orders/TOGGLE_INCLUDE_BETSLIPS",
  SET_ORDER_VALID: "orders/SET_ORDER_VALID",
  FETCH_FLAGS: "dashboard/FETCH_FLAGS",
  FETCH_FLAGS_SUCCEEDED: "dashboard/FETCH_FLAGS_SUCCEEDED",
  FETCH_FLAGS_FAILED: "dashboard/FETCH_FLAGS_FAILED",
}

export function fetchOrders(fixedFilter) {
  return { type: Type.FETCH_ORDERS, fixedFilter }
}

export function fetchOrdersSucceeded(page) {
  return { type: Type.FETCH_ORDERS_SUCCEEDED, page }
}

export function fetchOrdersFailed(error) {
  return { type: Type.FETCH_ORDERS_FAILED, error }
}

export function tableChanged(pagination, filter, sorter) {
  return { type: Type.TABLE_CHANGED, pagination, filter, sorter }
}

export function filterFieldsChanged(fields) {
  return { type: Type.FILTER_FIELDS_CHANGED, fields }
}

export function orderOpened(order) {
  return { type: Type.ORDER_OPENED, order }
}

export function orderClosed() {
  return { type: Type.ORDER_CLOSED }
}

export function fetchOrderPriceGraph(betslipUuid, bookie) {
  return { type: Type.FETCH_ORDER_PRICE_GRAPH, betslipUuid, bookie }
}

export function fetchOrderPriceGraphSucceeded(values, bookie) {
  return { type: Type.FETCH_ORDER_PRICE_GRAPH_SUCCEEDED, values, bookie }
}

export function fetchOrderPriceGraphFailed(error) {
  return { type: Type.FETCH_ORDER_PRICE_GRAPH_FAILED, error }
}

export function fetchModelPriceGraph(betslipUuid, homeScoreRate, awayScoreRate, nu, zeroAdjustment) {
  return {
    type: Type.FETCH_MODEL_PRICE_GRAPH,
    betslipUuid,
    homeScoreRate,
    awayScoreRate,
    nu,
    zeroAdjustment,
  }
}

export function fetchModelPriceGraphSucceeded(values) {
  return { type: Type.FETCH_MODEL_PRICE_GRAPH_SUCCEEDED, values }
}

export function fetchModelPriceGraphFailed(error) {
  return { type: Type.FETCH_MODEL_PRICE_GRAPH_FAILED, error }
}

export function fetchFixtureGraph(betslipUuid) {
  return { type: Type.FETCH_FIXTURE_GRAPH, betslipUuid }
}

export function fetchFixtureGraphSucceeded(values) {
  return { type: Type.FETCH_FIXTURE_GRAPH_SUCCEEDED, values }
}

export function fetchFixtureGraphFailed(error) {
  return { type: Type.FETCH_FIXTURE_GRAPH_FAILED, error }
}

export function fetchRunningballGraph(betslipUuid) {
  return { type: Type.FETCH_RUNNINGBALL_GRAPH, betslipUuid }
}

export function fetchRunningballGraphSucceeded(values) {
  return { type: Type.FETCH_RUNNINGBALL_GRAPH_SUCCEEDED, values }
}

export function fetchRunningballGraphFailed(error) {
  return { type: Type.FETCH_RUNNINGBALL_GRAPH_FAILED, error }
}

export function startBackgroundRefresh(fixedFilter) {
  return { type: Type.START_BACKGROUND_REFRESH, fixedFilter }
}

export function stopBackgroundRefresh() {
  return { type: Type.STOP_BACKGROUND_REFRESH }
}

export function fetchValues(field, fixedFilter) {
  return { type: Type.FETCH_VALUES, field, fixedFilter }
}

export function fetchValuesSucceeded(field, values, fixedFilter) {
  return { type: Type.FETCH_VALUES_SUCCEEDED, field, values, fixedFilter }
}

export function fetchValuesFailed(field, error, fixedFilter) {
  return { type: Type.FETCH_VALUES_FAILED, field, error, fixedFilter }
}

export function fetchOrderChildren(orderId) {
  return { type: Type.FETCH_ORDER_CHILDREN, orderId }
}

export function fetchOrderChildrenSucceeded(orders) {
  return { type: Type.FETCH_ORDER_CHILDREN_SUCCEEDED, orders }
}

export function fetchOrderChildrenFailed(error) {
  return { type: Type.FETCH_ORDER_CHILDREN_FAILED, error }
}

export function toggleIncludeBetslips() {
  return { type: Type.TOGGLE_INCLUDE_BETSLIPS }
}

export function setOrderValid(betslipUuid, valid) {
  return { type: Type.SET_ORDER_VALID, betslipUuid, valid }
}

export function fetchFlags(fixedFilter) {
  return { type: Type.FETCH_FLAGS, fixedFilter }
}

export function fetchFlagsSucceeded(flags) {
  return { type: Type.FETCH_FLAGS_SUCCEEDED, flags }
}

export function fetchFlagsFailed(error) {
  return { type: Type.FETCH_FLAGS_FAILED, error }
}
