import { Col, Layout, Row, Spin } from "antd"

const PageLoading = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col align="center">
          <Spin size="large" />
        </Col>
      </Row>
    </Layout>
  )
}

export default PageLoading
