import { useEffect, useRef } from "react"
import { GoogleOutlined } from "@ant-design/icons"
import { Button, Col, Layout, message, Row } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { oAuthSucceeded } from "../actions/user"
import "../less/Login.less"
import logo from "../resources/img/investant.svg"
import { Slant } from "./Slant"

const Login = () => {
  const dispatch = useDispatch()
  const error = useSelector((state) => state.login.error)
  const gSignInContainer = useRef(null)

  const onLoginSuccess = (res) => dispatch(oAuthSucceeded(res))

  useEffect(() => {
    const params = {
      client_id: "493693770533-oe99kut9cufqoaqi8sg88i07mfmtum4d.apps.googleusercontent.com",
      callback: onLoginSuccess,
      // One tap button config
      prompt_parent_id: "g-signin-container",
      context: "signin",
      cancel_on_tap_outside: false,
    }

    window.google.accounts.id.initialize(params)

    // Prompt one tap sign in
    window.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        // If not possible, show default google sign in button
        window.google.accounts.id.renderButton(gSignInContainer.current, {
          theme: "filled_blue",
          text: "signin_with",
          shape: "pill",
          logo_alignment: "left",
          width: 250,
          size: "large",
          locale: "en_US",
        })
      }
    })
  }, [dispatch]); // Add dispatch to dependencies

  useEffect(() => {
    if (error) {
      message.error(error.message || "UNKNOWN_ERROR")
    }
  }, [error]);

  return (
    <Layout>
      <Layout style={{ flexGrow: "1", minHeight: "100vh" }}>
        <Layout style={{ padding: "0 30px 30px 30px", position: "relative" }}>
          <Slant height="300px" />

          <Row justify="center" align="middle">
            <Col style={{ textAlign: "center" }}>
              <img src={logo} alt="Investant" className="login-logo" />
              <br />

              <div id="g-signin-container" ref={gSignInContainer} />
            </Col>
          </Row>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Login
