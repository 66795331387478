import { combineReducers } from "redux"
import { Type } from "../actions/user"

function error(state = null, action) {
  switch (action.type) {
    case Type.SIGN_IN_FAILED:
      return action.error
    case Type.OAUTH_FAILED:
      return { message: "OAUTH_FAILED" }

    case Type.SIGN_IN_SUCCEEDED:
    case Type.OAUTH_SUCCEEDED:
      return null

    default:
      return state
  }
}

const reducer = combineReducers({
  error,
})

export default reducer
