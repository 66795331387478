import { combineReducers } from "redux"
import analytics from "./analytics"
import balances from "./balances"
import competitions from "./competitions"
import dashboard from "./dashboard"
import login from "./login"
import orders from "./orders"
import user from "./user"
import backtesting from "./backtesting"
import bots from "./bots"
import overview from "./overview"
import betexplorer from "./betexplorer"
import gamecenter from "./gamecenter"
import users from "./users"
import everymatrix from "./everymatrix"
import runningball from "./runningball"
import broker from "./broker"
import model from "./model"

const reducer = combineReducers({
  login,
  user,
  orders,
  competitions,
  dashboard,
  analytics,
  balances,
  backtesting,
  bots,
  overview,
  betexplorer,
  gamecenter,
  users,
  everymatrix,
  runningball,
  broker,
  model,
})

export default reducer
