import { combineReducers } from "redux"
import { Type } from "../actions/bots"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_BOTS:
      return true
    case Type.FETCH_BOTS_FAILED:
    case Type.FETCH_BOTS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_BOTS_FAILED:
      return action.error
    case Type.FETCH_BOTS:
      return null
    default:
      return state
  }
}

function page(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_BOTS_SUCCEEDED:
      return action.page
    case Type.FETCH_BOTS_FAILED:
      return {}
    default:
      return state
  }
}

function selectedBot(state = null, action) {
  switch (action.type) {
    case Type.BOT_SELECTED:
      return action.bot
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  page,
  selectedBot,
})

export default reducer
