export const Type = {
  FETCH_OVERVIEW_STATS: "overview/FETCH_OVERVIEW_STATS",
  FETCH_OVERVIEW_STATS_SUCCEEDED: "overview/FETCH_OVERVIEW_STATS_SUCCEEDED",
  FETCH_OVERVIEW_STATS_FAILED: "overview/FETCH_OVERVIEW_STATS_FAILED",
  FETCH_OVERVIEW_GRAPH: "overview/FETCH_OVERVIEW_GRAPH",
  FETCH_OVERVIEW_GRAPH_SUCCEEDED: "overview/FETCH_OVERVIEW_GRAPH_SUCCEEDED",
  FETCH_OVERVIEW_GRAPH_FAILED: "overview/FETCH_OVERVIEW_GRAPH_FAILED",
  SET_SELECTED_BOTS: "overview/SET_SELECTED_BOTS",
}

export function fetchOverviewStats(accountId) {
  return { type: Type.FETCH_OVERVIEW_STATS, accountId }
}

export function fetchOverviewStatsSucceeded(page) {
  return { type: Type.FETCH_OVERVIEW_STATS_SUCCEEDED, page }
}

export function fetchOverviewStatsFailed(error) {
  return { type: Type.FETCH_OVERVIEW_STATS_FAILED, error }
}

export function fetchOverviewGraph(accountId) {
  return { type: Type.FETCH_OVERVIEW_GRAPH, accountId }
}

export function fetchOverviewGraphSucceeded(page) {
  return { type: Type.FETCH_OVERVIEW_GRAPH_SUCCEEDED, page }
}

export function fetchOverviewGraphFailed(error) {
  return { type: Type.FETCH_OVERVIEW_GRAPH_FAILED, error }
}

export function setSelectedBots(accountId, selectedBots) {
  return { type: Type.SET_SELECTED_BOTS, accountId, selectedBots }
}
