import { useSelector } from "react-redux"

const Discretion = ({ children = null }) => {
  const discretionMode = useSelector(state => state.user.discretionMode)
  
  return discretionMode ? (
    <span
      className="discretion"
      style={{
        color: "transparent",
        textShadow: "0 0 11px rgba(0,0,0,0.5)",
      }}
    >
      {children}
    </span>
  ) : (
    children
  )
}

export default Discretion
