import { all, call, put, takeLatest } from "redux-saga/effects"
import {
  Type,
  fetchUsersSucceeded,
  fetchUsersFailed,
  deleteUserSucceeded,
  deleteUserFailed,
  createUserSucceeded,
  createUserFailed,
  assignUserAuthoritiesSucceeded,
  assignUserAuthoritiesFailed,
  assignUserAccountsSucceeded,
  assignUserAccountsFailed,
} from "../actions/users"
import { get, httpDelete, post } from "../lib/fetch"
import { message } from "antd"

function* watchFetchUsers() {
  return yield takeLatest(Type.FETCH_USERS, fetchUsers)
}

function* fetchUsers() {
  try {
    const users = yield call(get, "/api/user/users")
    yield put(fetchUsersSucceeded(users))
  } catch (e) {
    yield put(fetchUsersFailed(e))
  }
}

// ---

function* watchDeleteUser() {
  return yield takeLatest(Type.DELETE_USER, deleteUser)
}

function* deleteUser({ userId }) {
  try {
    yield call(httpDelete, `/api/user/users/${userId}`)
    yield put(deleteUserSucceeded(userId))
  } catch (e) {
    message.error(e.message, 5)
    yield put(deleteUserFailed(e))
  }
}

// ---

function* watchCreateUser() {
  return yield takeLatest(Type.CREATE_USER, createUser)
}

function* createUser({ user }) {
  try {
    const createdUser = yield call(post, `/api/user/users`, user)
    yield put(createUserSucceeded(createdUser))
  } catch (e) {
    message.error(e.message, 5)
    yield put(createUserFailed(e))
  }
}

// ---

function* watchAssignUserAuthorities() {
  return yield takeLatest(Type.ASSIGN_USER_AUTHORITIES, assignUserAuthorities)
}

function* assignUserAuthorities({ userId, authorities }) {
  try {
    yield call(post, `/api/user/users/authorities`, { userId, authorities })
    yield put(assignUserAuthoritiesSucceeded(userId, authorities))
  } catch (e) {
    message.error(e.message, 5)
    yield put(assignUserAuthoritiesFailed(e))
  }
}

// ---

function* watchAssignUserAccounts() {
  return yield takeLatest(Type.ASSIGN_USER_ACCOUNTS, assignUserAccounts)
}

function* assignUserAccounts({ userId, accountNames }) {
  try {
    yield call(post, `/api/user/users/accounts`, { userId, accountNames })
    yield put(assignUserAccountsSucceeded(userId, accountNames))
  } catch (e) {
    message.error(e.message, 5)
    yield put(assignUserAccountsFailed(e))
  }
}

// ---

export default function* userSaga() {
  yield all([
    watchFetchUsers(),
    watchDeleteUser(),
    watchCreateUser(),
    watchAssignUserAuthorities(),
    watchAssignUserAccounts(),
  ])
}
